import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl, intlShape } from 'react-intl'
import { Media } from 'react-md'
import { translateString } from 'utils/I18nHelper'
import { trackCardOpened } from 'utils/GTM';
import { resizeImage } from 'utils/Image'
import { listingShape } from 'constants/SharedPropTypes'
import ImageFallback from 'components/ImageFallback'
import formatAddress from 'components/Address/FormatAddress'

const propTypes = {
  guidebook: PropTypes.object,
  listing: listingShape,
  className: PropTypes.string,
  onClose: PropTypes.func,
  // helper for internationalization
  intl: intlShape.isRequired
}

class MapListingOverlay extends Component {
  constructor(props) {
    super(props)
    this.closePopup = this.closePopup.bind(this)
    this.openListing = this.openListing.bind(this)
  }

  closePopup = (e) => {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose(e)
    }
  }

  openListing = (listing, e) => {
    e.stopPropagation()
    const guidebook_id = this.props.guidebook.id || 0
    const host_user_id = this.props.guidebook.host_user_id || 0
    const tab_name = 'book_again'
    const card_name = this.props.listing.name || null
    // send card_opened event to GTM/GA4
    trackCardOpened(guidebook_id, host_user_id, tab_name, card_name, null);
    window.open(
      listing.booking_link,
      '_blank'
    );
  }

  render() {
    const { listing, className, intl } = this.props
    const aspectRatio = '4-3'
    const fallbackImage = '/add-place-img.svg'
    let srcImage = '/add-place-img.svg'
    if (listing.image) {
      srcImage = resizeImage(listing.image, 320, 240, true)
    }
    const { addressPrimary, addressSecondary } = formatAddress(
      listing.address,
      true
    )
    const listingNameTextString = translateString(
      intl.locale,
      listing.name,
      listing.name_txn
    )
    const listingQuoteTextString = translateString(
      intl.locale,
      listing.description,
      listing.description_txn
    )

    return (
      <div className={className} onClick={this.closePopup}>
        <div className="hf-map-popup">
          <div
            className="hf-map-popup-content md-grid"
            onClick={(e) => {
              this.openListing(listing, e)
            }}
          >
            <div className="md-cell md-cell--7 md-cell--3-phone hf-map-popup-info md-text-left">
              <div className="hf-map-popup-title" title={listingNameTextString}>
                {listingNameTextString}
              </div>
              <div className="hf-map-address">
                <div className="hf-map-address-line">{addressPrimary}</div>
                <div className="hf-map-address-line">{addressSecondary}</div>
              </div>
            </div>
            <div className="md-cell md-cell--5 md-cell--1-phone">
              <Media aspectRatio={aspectRatio}>
                <ImageFallback
                  src={srcImage}
                  fallback={fallbackImage}
                  alt=""
                  className="hf-map-popup-image"
                />
              </Media>
            </div>
            <div
              className="md-cell md-cell--12 hf-map-why-recommend md-text-left"
              title={listingQuoteTextString}
            >
              {listingQuoteTextString}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MapListingOverlay.propTypes = propTypes
MapListingOverlay.contextTypes = {
  router: PropTypes.object
}

export default connect(null)(injectIntl(MapListingOverlay))
