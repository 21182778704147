import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { injectIntl, intlShape } from 'react-intl'
import { Media } from 'react-md'
import ImageFallback from 'components/ImageFallback'
import { trackCardOpened } from 'utils/GTM';
import { translateString } from 'utils/I18nHelper'
import { resizeImage } from 'utils/Image'
import { buildRecURL } from 'utils/Urls'
import { recommendationShape } from 'constants/SharedPropTypes'
import formatAddress from 'components/Address/FormatAddress'

// import { trackEvent } from 'utils/Segment';

const propTypes = {
  guidebook: PropTypes.object,
  recsOnly: PropTypes.bool,
  rec: recommendationShape,
  className: PropTypes.string,
  onClose: PropTypes.func,
  // helper for internationalization
  intl: intlShape.isRequired
}

class MapRecOverlay extends Component {
  constructor(props) {
    super(props)
    this.closePopup = this.closePopup.bind(this)
    this.openRec = this.openRec.bind(this)
  }

  closePopup = (e) => {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose(e)
    }
  }

  openRec = (rec, e) => {
    const guidebook_id = this.props.guidebook.id || 0
    const host_user_id = this.props.guidebook.host_user_id || 0
    const tab_name = rec.category.name.toLowerCase().replace(/ /g, '_') || 'unknown'
    const card_key = rec.key || null
    const card_name = rec.name || null
    // send card_opened event to GTM/GA4
    trackCardOpened(guidebook_id, host_user_id, tab_name, card_name, card_key);
    const recUrl = buildRecURL(rec, this.props.guidebook, this.props.recsOnly)
    this.props.dispatch(push(recUrl))
  }

  render() {
    const { rec, className, intl } = this.props
    const aspectRatio = '4-3'
    const fallbackImage = '/add-place-img.svg'
    let srcImage = '/add-place-img.svg'
    if (rec.image) {
      srcImage = resizeImage(rec.image, 320, 240, true)
    }
    const { addressPrimary, addressSecondary } = formatAddress(
      rec.address,
      true
    )
    const recNameTextString = translateString(
      intl.locale,
      rec.name,
      rec.name_txn
    )
    const recQuoteTextString = translateString(
      intl.locale,
      rec.why_recommended,
      rec.why_recommended_txn
    )

    return (
      <div className={className} onClick={this.closePopup}>
        <div className="hf-map-popup">
          <div
            className="hf-map-popup-content md-grid"
            onClick={(e) => {
              this.openRec(rec, e)
            }}
          >
            <div className="md-cell md-cell--7 md-cell--3-phone hf-map-popup-info md-text-left">
              <div className="hf-map-popup-title" title={recNameTextString}>
                {recNameTextString}
              </div>
              <div className="hf-map-address">
                <div className="hf-map-address-line">{addressPrimary}</div>
                <div className="hf-map-address-line">{addressSecondary}</div>
              </div>
            </div>
            <div className="md-cell md-cell--5 md-cell--1-phone">
              <Media aspectRatio={aspectRatio}>
                <ImageFallback
                  src={srcImage}
                  fallback={fallbackImage}
                  alt=""
                  className="hf-map-popup-image"
                />
              </Media>
            </div>
            <div
              className="md-cell md-cell--12 hf-map-why-recommend md-text-left"
              title={recQuoteTextString}
            >
              {recQuoteTextString}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MapRecOverlay.propTypes = propTypes
MapRecOverlay.contextTypes = {
  router: PropTypes.object
}

export default connect(null)(injectIntl(MapRecOverlay))
