import cn from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { nl2br } from 'utils/Strings'
import { transformCustomVariables } from 'utils/Strings'
import { findGuidebookUrl } from 'utils/Urls'

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  text: PropTypes.string,
  translations: PropTypes.object,
  className: PropTypes.string,
  variableData: PropTypes.object
}

const defaultProps = {
  className: '',
  splitBr: true,
  variableData: {}
}

class AsHtml extends Component {
  createYoutubeEmbed = (key) => {
    return (
      '<iframe width="568" height="320" src="https://www.youtube.com/embed/' +
      key +
      '?playsinline=1&fs=0" frameBorder="0" seamless="seamless"></iframe><br/>'
    )
  }

  createTikTokEmbed(url) {
    const videoId = url.split('/').pop().split('?')[0]

    return (
      '<iframe style="height: 568px;min-width: 325px;" src="https://www.tiktok.com/embed/v2/' +
      videoId +
      '?lang=en-US&amp;" frameBorder="0" seamless="seamless"></iframe><br/>'
    )
  }

  createShortsEmbed = (key) => {
    return (
      '<iframe width="320" height="568" src="https://www.youtube.com/embed/' +
      key +
      '?playsinline=1&fs=0" frameBorder="0" seamless="seamless"></iframe><br/>'
    )
  }

  createVimeoEmbed = (key) => {
    return (
      '<iframe src="https://player.vimeo.com/video/' +
      key +
      '" width="420" height="345" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe><br/>'
    )
  }

  createMatterPortEmbed = (key) => {
    return (
      '<iframe src="https://my.matterport.com/show/?m=' +
      key +
      '" width="420" height="345" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe><br/>'
    )
  }

  createPDFEmbed = (url) => {
    const embedSrc = `https://docs.google.com/gview?url=${url}&embedded=true`
    return (
      '<iframe src=' +
      embedSrc +
      ' width="420" height="543" frameborder="0" allowfullscreen></iframe><br/><span>A pdf should appear above. If it does not, please reload the page or <a href="' +
      embedSrc +
      '">click here</a></span><br/>'
    )
  }

  transformYoutubeLinks = (text) => {
    if (!text) return text

    const self = this
    const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g
    const fullreg =
      /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^&^" \n<]+)(?:[^ \n"<]+)?/g
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g

    let resultHtml = text

    // get all the matches for youtube links using the first regex
    const match = text.match(fullreg)
    if (match && match.length > 0) {
      // get all links and put in placeholders
      const matchlinks = text.match(linkreg)
      if (matchlinks && matchlinks.length > 0) {
        for (var i = 0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace(
            matchlinks[i],
            '#placeholder' + i + '#'
          )
        }
      }

      // now go through the matches one by one
      for (var j = 0; j < match.length; j++) {
        // get the key out of the match using the second regex
        let matchParts = match[j].split(regex)
        // replace the full match with the embedded youtube code
        resultHtml = resultHtml.replace(
          match[j],
          self.createYoutubeEmbed(matchParts[1])
        )
      }

      // ok now put our links back where the placeholders were.
      if (matchlinks && matchlinks.length > 0) {
        for (var k = 0; k < matchlinks.length; k++) {
          resultHtml = resultHtml.replace(
            '#placeholder' + k + '#',
            matchlinks[k]
          )
        }
      }
    }
    return resultHtml
  }

  transformTikTokLinks = (text) => {
    if (!text) return text

    const tiktokRegex = /https:\/\/www\.tiktok\.com\/@[\w.-]+\/video\/\d+[^<]+/g
    const newText = text.replace(tiktokRegex, (match) =>
      this.createTikTokEmbed(match)
    )

    return newText
  }

  transformShortsLinks = (text) => {
    if (!text) return text

    const self = this
    const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g
    const fullreg =
      /(https?:\/\/)?(www\.)?(youtube\.com\/shorts\/)([^&^" \n<]+)(?:[^ \n"<]+)?/g
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/shorts\/)([^& \n<]+)(?:[^ \n<]+)?/g

    let resultHtml = text

    // get all the matches for youtube links using the first regex
    const match = text.match(fullreg)
    if (match && match.length > 0) {
      // get all links and put in placeholders
      const matchlinks = text.match(linkreg)
      if (matchlinks && matchlinks.length > 0) {
        for (var i = 0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace(
            matchlinks[i],
            '#placeholder' + i + '#'
          )
        }
      }

      // now go through the matches one by one
      for (var j = 0; j < match.length; j++) {
        // get the key out of the match using the second regex
        let matchParts = match[j].split(regex)
        // replace the full match with the embedded youtube code
        resultHtml = resultHtml.replace(
          match[j],
          self.createShortsEmbed(matchParts[1])
        )
      }

      // ok now put our links back where the placeholders were.
      if (matchlinks && matchlinks.length > 0) {
        for (var k = 0; k < matchlinks.length; k++) {
          resultHtml = resultHtml.replace(
            '#placeholder' + k + '#',
            matchlinks[k]
          )
        }
      }
    }
    return resultHtml
  }

  transformVimeoLinks = (text) => {
    if (!text) return text

    const self = this
    const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g
    const fullreg =
      /(?<!src=["'])(https?:\/\/)?(player\.vimeo\.com\/video\/|vimeo\.com\/)([^&^" \n<]+)(?:[^ \n"<]+)?/g
    const regex =
      /(?:https?:\/\/)?(?:player\.vimeo\.com\/video\/|vimeo\.com\/)([^&^ \n<]+)(?:[^ \n<]+)?/

    let resultHtml = text

    // Use a different method to ensure we don't alter iframe src attributes
    const iframeReg =
      /<iframe[^>]*src=["']https?:\/\/(?:player\.vimeo\.com\/video\/|vimeo\.com\/)[^"']+["'][^>]*><\/iframe>/g

    // Extract iframes to exclude them
    const iframeMatches = text.match(iframeReg)
    if (iframeMatches && iframeMatches.length > 0) {
      for (let i = 0; i < iframeMatches.length; i++) {
        resultHtml = resultHtml.replace(
          iframeMatches[i],
          `#iframe_placeholder${i}#`
        )
      }
    }

    // Get all the matches for Vimeo links using the full regex
    const match = resultHtml.match(fullreg)
    if (match && match.length > 0) {
      // Get all links and put in placeholders
      const matchlinks = resultHtml.match(linkreg)
      if (matchlinks && matchlinks.length > 0) {
        for (let i = 0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace(matchlinks[i], `#placeholder${i}#`)
        }
      }

      // Now go through the Vimeo matches one by one
      for (let j = 0; j < match.length; j++) {
        // Get the key out of the match using the second regex
        const matchParts = match[j].match(regex)
        if (matchParts) {
          // Replace the full match with the embedded Vimeo code
          resultHtml = resultHtml.replace(
            match[j],
            self.createVimeoEmbed(matchParts[1])
          )
        }
      }

      // Put the links back where the placeholders were
      if (matchlinks && matchlinks.length > 0) {
        for (let k = 0; k < matchlinks.length; k++) {
          resultHtml = resultHtml.replace(`#placeholder${k}#`, matchlinks[k])
        }
      }
    }

    // Put the iframes back where the placeholders were
    if (iframeMatches && iframeMatches.length > 0) {
      for (let i = 0; i < iframeMatches.length; i++) {
        resultHtml = resultHtml.replace(
          `#iframe_placeholder${i}#`,
          iframeMatches[i]
        )
      }
    }

    return resultHtml
  }

  transformMatterPortLinks = (text) => {
    if (!text) return text

    // embed link looks like https://my.matterport.com/show/?m=Q1i2EmP1SkN
    const self = this
    const linkreg = /(?:)<a([^>]+)>(.+?)<\/a>/g
    const fullreg =
      /(https?:\/\/)?(my\.matterport\.com\/show\/\?m=)([^&^" \n<]+)(?:[^ \n"<]+)?/g
    const regex =
      /(?:https?:\/\/)?(?:my\.matterport\.com\/show\/\?m=)([^&^ \n<]+)(?:[^ \n<]+)?/g

    let resultHtml = text

    // get all the matches for matterport links using the first regex
    const match = text.match(fullreg)
    if (match && match.length > 0) {
      // get all links and put in placeholders
      const matchlinks = text.match(linkreg)
      if (matchlinks && matchlinks.length > 0) {
        for (var i = 0; i < matchlinks.length; i++) {
          resultHtml = resultHtml.replace(
            matchlinks[i],
            '#placeholder' + i + '#'
          )
        }
      }

      // now go through the matches one by one
      for (var j = 0; j < match.length; j++) {
        // get the key out of the match using the second regex
        let matchParts = match[j].split(regex)
        // replace the full match with the embedded matterport code
        resultHtml = resultHtml.replace(
          match[j],
          self.createMatterPortEmbed(matchParts[1])
        )
      }

      // ok now put our links back where the placeholders were.
      if (matchlinks && matchlinks.length > 0) {
        for (var k = 0; k < matchlinks.length; k++) {
          resultHtml = resultHtml.replace(
            '#placeholder' + k + '#',
            matchlinks[k]
          )
        }
      }
    }
    return resultHtml
  }

  transformCardLinks = (text) => {
    if (!text) return text

    let resultHtml = text
    let replaceLink = ''
    const cardLinkReg = /(?:)\[hf-card-link([^[]+)\](.+?)\[\/hf-card-link\]/g
    const matchlinks = text.match(cardLinkReg)
    if (matchlinks && matchlinks.length > 0) {
      for (var i = 0; i < matchlinks.length; i++) {
        // trim off what we need
        let scratch = matchlinks[i].substring(15)
        let url = scratch.split('"', 1)[0]
        let urlSplit = url.split('/', 2)
        scratch = scratch.substring(0, scratch.length - 15)
        let text = scratch.substring(scratch.indexOf(']') + 1)
        let { guidebookUrl, recsOnly } = findGuidebookUrl(this.props.domain)
        // ditch anything after a ?
        let gbParts = guidebookUrl.split('?')
        guidebookUrl = gbParts[0]
        // if we have a recs-only link make sure not to build links to non-rec links
        if (
          guidebookUrl &&
          recsOnly &&
          urlSplit.length > 1 &&
          ['arrival', 'house_manual', 'departure'].indexOf(urlSplit[1]) !== -1
        ) {
          replaceLink = `<a href="javascript:null" title="link not allowed in recs-only guidebooks" style="cursor: not-allowed;">${text}</a>`
          resultHtml = resultHtml.replace(matchlinks[i], replaceLink)
        } else if (guidebookUrl) {
          replaceLink = `<a href="${guidebookUrl}${url}">${text}</a>`
          resultHtml = resultHtml.replace(matchlinks[i], replaceLink)
        } else {
          replaceLink = `<a href="javascript:null" title="invalid link" style="cursor: not-allowed;">${text}</a>`
          resultHtml = resultHtml.replace(matchlinks[i], replaceLink)
        }
      }
    }
    return resultHtml
  }

  transformPDF = (text) => {
    if (!text) return text

    const self = this
    let resultHtml = text
    const pdfReg = /(?:)\[hf-pdf([^[]+)\]\[\/hf-pdf\]/g
    const matches = text.match(pdfReg)
    if (matches && matches.length > 0) {
      for (var i = 0; i < matches.length; i++) {
        // trim off what we need
        let scratch = matches[i].substring(9)
        let url = scratch.split('"', 1)[0]
        resultHtml = resultHtml.replace(matches[i], self.createPDFEmbed(url))
      }
    }
    return resultHtml
  }

  render() {
    let text
    if (
      this.props.intl.locale === 'en' ||
      !this.props.translations ||
      !this.props.translations[this.props.intl.locale]
    ) {
      text = this.props.text
    } else {
      text = this.props.translations[this.props.intl.locale]
    }
    if (text && this.props.splitBr) {
      text = nl2br(text)
    }
    text = this.transformYoutubeLinks(text)
    text = this.transformTikTokLinks(text)
    text = this.transformShortsLinks(text)
    text = this.transformCardLinks(text)
    text = this.transformVimeoLinks(text)
    text = this.transformMatterPortLinks(text)
    text = this.transformPDF(text)
    text = transformCustomVariables(text, this.props.variableData)

    const innerHtml = { __html: text }
    const textHtml = (
      <span
        className={cn('ashtml-span', this.props.className)}
        dangerouslySetInnerHTML={innerHtml}
      />
    )

    return textHtml
  }
}

AsHtml.propTypes = propTypes
AsHtml.defaultProps = defaultProps

function mapStateToProps(state, props) {
  const customDomain =
    state.guidebook &&
    state.guidebook.data &&
    state.guidebook.data.domain &&
    state.guidebook.data.domain.status &&
    state.guidebook.data.domain.status === 'approved'
      ? state.guidebook.data.domain.domain
      : null
  return {
    intl: state.intl,
    domain: customDomain
  }
}

export default connect(mapStateToProps)(AsHtml)
