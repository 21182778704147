import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Avatar, FontIcon, ListItem } from 'react-md'
import { resizeImage } from 'utils/Image'
import { ucFirst } from 'utils/Strings'

class ListingListItem extends Component {
  formatSecondary = (listing) => {
    if (
      listing.address &&
      listing.address.locality &&
      listing.address.street &&
      listing.address.post_code
    ) {
      return `${listing.address.street_number ? listing.address.street_number + ' ' : ''}${listing.address.street}, ${listing.address.locality} (${listing.address.post_code})`
    } else if (listing.address && listing.address.locality && listing.address.street) {
      return `${listing.address.street}, ${listing.address.locality}`
    } else if (listing.address && listing.address.locality) {
      return listing.address.locality
    } else {
      return 'Locationless Listing'
    }
  }

  render() {
    const { item, editItem, deleteItem, pluralName } = this.props
    const link = (
      <Link
        to={'/host/' + pluralName + '/' + item.id}
        onClick={(e) => {
          e.stopPropagation()
        }}
        className="md-tile-text--primary md-text hf-fake-link"
      >
        {item.name}
      </Link>
    )
    const image = resizeImage(item.image, 50, 50, true)
    
    const providerLabel = item.external_provider
      ? ucFirst(item.external_provider)
      : 'unknown provider'
    const providerRef = item.external_id
    const providerSpan = item.external_provider ? (
      <span className="item-list-sponsored">
        <FontIcon>loyalty</FontIcon> {providerLabel}
      </span>
    ) : null
    const idSpan = item.external_id ? (
      <span className="item-list-sponsored">
        <FontIcon>pin</FontIcon> {providerRef}
      </span>
    ) : null
    const fullSecondary = (
      <div>
        <span>{this.formatSecondary(item)}</span>
        <br />
        {providerSpan} {idSpan}
      </div>
    )
    return (
      <ListItem
        onClick={editItem}
        className={'hf-list-item hf-' + pluralName + 'list-item'}
        leftAvatar={
          <Link
            to={'/host/' + pluralName + '/' + item.id}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Avatar src={image} role="presentation" />
          </Link>
        }
        primaryText={link}
        secondaryText={fullSecondary}
        rightIcon={<FontIcon onClick={deleteItem}>delete</FontIcon>}
        threeLines
      />
    )
  }
}

ListingListItem.propTypes = {
  item: PropTypes.object.isRequired
}
export default ListingListItem
