import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { replace } from 'react-router-redux'
import { actions } from 'react-redux-form'
import {
  Button,
  CardActions,
  Tabs,
  Tab,
  TabsContainer
} from 'react-md'
import ManageSelectedCards from '../ManageSelectedCards'
import ManageListingsSelectedTab from './ManageListingsSelectedTab'
// import {trackEvent, getHostIdAndKey} from 'utils/Segment';

class ManageListings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 1,
      filter: ''
    }
    this.statusInterval = null
    this.onTabChange = this.onTabChange.bind(this)
    this.tabContentChanged = this.tabContentChanged.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { selected_listings } = this.props
    //if selected items have loaded setState, otherwise this is the user changes to the model (ie activeTabIndex is 0)
    if (
      selected_listings &&
      nextProps.selected_listings &&
      selected_listings !== nextProps.selected_listings &&
      this.state.activeTabIndex === 1
    ) {
      let initialTabIndex =
        nextProps.selected_listings.length > 0 ? 1 : 0
      this.setState({
        activeTabIndex: initialTabIndex
      })
    }
  }

  createCard(type) {
    const { dispatch, item_id, pluralName, singularName } = this.props
    // if no id set, they must be creating a guidebook
    const id = item_id ? item_id : 'create'
    // BA. bit of a hack for when they edit a card and return with a hash in the URL, then refresh the whole browser
    dispatch(actions.change('edit_' + singularName + '.noReload', true))
    dispatch(replace(`/host/${pluralName}/${id}/${type}/create`))
  }

  onTabChange = (newTabIndex, event) => {
    this.setState({
      activeTabIndex: newTabIndex
    })
  }

  /*
   BA a bit of a hack to workaround an issue in react-md tabs. Let the tab notify the parent that it's content has changed.
   This function just sets a new state to force a recalc of heights etc
   */
  tabContentChanged = () => {
    this.setState({
      tabContentChanged: new Date()
    })
  }

  refreshPage = () => {
    const refreshUrl = `/host/guidebooks/${this.props.item_id}#listings#listing`
    window.location.href = refreshUrl
    window.location.reload()
  }

  render() {
    const { pluralName, singularName } = this.props
    return (
      <div id="listing">
        <TabsContainer
          className="hf-editable-card-tabs"
          onTabChange={this.onTabChange}
          activeTabIndex={this.state.activeTabIndex}
        >
          <Tabs tabId="tab">
            <Tab key="select" label="Select/Edit">
              <ManageSelectedCards
                ownerPluralName={pluralName}
                ownerSingularName={singularName}
                pluralName="listings"
                singularName="listing"
                active={this.state.activeTabIndex === 0}
                onTabContentChanged={this.tabContentChanged}
                showHeading={false}
              />
            </Tab>
            <Tab key="order" label="Sort">
              <ManageListingsSelectedTab
                ownerPluralName={pluralName}
                ownerSingularName={singularName}
                active={this.state.activeTabIndex === 1}
                {...this.props}
              />
            </Tab>
          </Tabs>
        </TabsContainer>
        <CardActions>
          <Button
            onClick={(e) => {
              this.createCard('listings')
            }}
            flat
            primary
          >
            Create Book Again Listing
          </Button>
        </CardActions>
      </div>
    )
  }
}

ManageListings.propTypes = {
  defaultOpen: PropTypes.bool,
  singularName: PropTypes.string.isRequired,
  onWizardClick: PropTypes.func
}

ManageListings.defaultProps = {
  defaultOpen: false,
  onWizardClick: () => {}
}

function mapStateToProps(state, props) {
  const { singularName } = props
  const edit_item = state['edit_' + singularName]
  const selected_listings = edit_item.listings || []
  return {
    item_id: edit_item.id,
    selected_listings: selected_listings
  }
}

export default connect(mapStateToProps)(ManageListings)
