import { getToken } from 'utils/Auth'

import 'whatwg-fetch'

/**
 * save new order of categories for user
 * server will use jwt to determine user
 * data is array objects defined as:  {"category_id": 1 , "category_order": 2};
 */
export function saveOrder(new_order) {
  const path = '/api/v1/category_orders/save_order'
  const method = 'POST'
  const url = process.env.REACT_APP_API_URL + path
  const body = {}
  body['order_params'] = new_order
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}

export function convertToListings(category_id) {
  const path = `/api/v1/listings/build_from_category/${category_id}`
  const method= 'POST'
  const url = process.env.REACT_APP_API_URL + path
  const body = {}
  const token = getToken()
  const config = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: JSON.stringify(body)
  }
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.json())
}
