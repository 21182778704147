import React from 'react'
import { Button } from 'react-md'
import { withRouter } from 'react-router'

import { getUser } from '../../utils/Auth'

class OpenBetaBanner extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      doNotShow: false,
      open: false
    }
  }

  componentWillMount() {
    this.unListen = this.props.history.listen((location) => {
      if (['/login', '/signup'].includes(location.pathname)) {
        this.setState({ open: false })
        document.getElementById('mainContainer').classList.remove('hf-banner')
      }
    })
  }

  componentWillUnmount() {
    this.unListen()
  }

  componentDidMount() {
    const user = getUser()
    const flag = localStorage.getItem('hf_legacy_host')

    if (
      window.isIframe ||
      !user ||
      (!window.location.pathname.includes('/host') &&
        !window.location.pathname.includes('/admin') &&
        window.location.pathname !== '/')
    ) {
      return
    }

    if (flag === 'true') {
      this.setState({ open: true })
      document.getElementById('mainContainer').classList.add('hf-banner')
      return
    }

    window.location.href =
      process.env.REACT_APP_V3_BASE_URL + window.location.pathname
  }

  saveFlag = () => {
    localStorage.removeItem('hf_legacy_host')
    this.setState({ open: false })
    window.location.href = process.env.REACT_APP_V3_BASE_URL
  }

  handleDismiss = () => {
    this.setState({ open: false })
    document.getElementById('mainContainer').classList.remove('hf-banner')
  }

  render() {
    return (
      <div
        className={`gbHostOpenBetaBanner ${this.state.open ? 'open' : 'close'}`}
      >
        <div className="gbHost-title">Modern Guidebooks Management</div>
        <div className="gbHost-content">
          <strong>Improved Guidebook Hosting is here!</strong> Our digital books
          just got a makeover.
        </div>

        <Button className="outlined" onClick={this.handleDismiss} flat>
          Dismiss
        </Button>
        <Button onClick={this.saveFlag} raised>
          Let's go
        </Button>
      </div>
    )
  }
}

export default withRouter(OpenBetaBanner)
