import PickerOverlay from 'filestack-react'
import PropTypes from 'prop-types'
import ImageResize from 'quill-image-resize-module-react'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import {
  Button,
  DialogContainer,
  SelectField,
  Tab,
  Tabs,
  TabsContainer,
  TextField
} from 'react-md'
import ReactQuill from 'react-quill'
import { connect } from 'react-redux'
import { actions, Control } from 'react-redux-form'

import { autoTranslateCodes, localeMessages } from 'constants/UIConstants'
import { fetchItems } from 'redux/modules/crud'
import { fixRotation } from 'redux/modules/filestack'
import { addToast } from 'redux/modules/toast'
import { translateText } from 'redux/modules/translation'
import { nl2br } from 'utils/Strings'
import { fieldHasContent } from 'utils/ValidationHelpers'

import TextFieldForm from './TextFieldForm'

const propTypes = {
  required: PropTypes.bool,
  simpleEditor: PropTypes.bool,
  minimalTools: PropTypes.bool,
  model: PropTypes.string,
  field: PropTypes.string.isRequired,
  txn_field: PropTypes.string.isRequired,
  locales: PropTypes.array.isRequired,
  max_length: PropTypes.number,
  rows: PropTypes.number,
  suppressEnter: PropTypes.bool,
  placeholder: PropTypes.string,
  ignoreModel: PropTypes.bool,
  defaultValue: PropTypes.string,
  availableVariables: PropTypes.array
}

const defaultProps = {
  placeholder: 'Enter desired rich text here',
  suppressEnter: false,
  ignoreModel: false,
  minimalTools: false,
  availableVariables: []
}

class MultilangWysiwyg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 0,
      selection: null,
      selectedText: null,
      showCardInput: false,
      showCardInputError: false,
      showVarInput: false,
      showVarInputError: false,
      defaultValueUsed: false,
      locales: props.locales
    }
    // internal variable for save references to quill stuff
    this.fileStackRef = null
    this.fileStackPDFRef = null
    this.quillRef = null
    this.reactQuillRefs = []
    this.cursorIdx = 0
    this.cardInputRef = null
    this.varSelectRef = null
    this.varInputRef = null
    // the normal method binding
    this.handleEnter = this.handleEnter.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleInsertLink = this.handleInsertLink.bind(this)
    this.imageHandler = this.imageHandler.bind(this)
    this.cardHandler = this.cardHandler.bind(this)
    this.imageUploaded = this.imageUploaded.bind(this)
    this.pdfUploaded = this.pdfUploaded.bind(this)
    this.modules = this.getModules()
  }

  componentDidMount() {
    const self = this
    const tooltipButtons = document.querySelectorAll('.ql-formats > button')
    const tooltipPickers = document.querySelectorAll(
      '.ql-formats > span.ql-picker'
    )
    const tooltipPickerLabels = document.querySelectorAll(
      '.ql-formats > span.ql-picker > .ql-picker-label'
    )
    tooltipButtons.forEach(function (tooltipButton, index) {
      tooltipButton.addEventListener('mouseover', () =>
        self.repositionTooltip(tooltipButton)
      )
      tooltipButton.addEventListener('focus', () =>
        self.repositionTooltip(tooltipButton)
      )
    })
    tooltipPickers.forEach(function (tooltipPicker, index) {
      tooltipPicker.addEventListener('mouseover', () =>
        self.repositionTooltip(tooltipPicker)
      )
      tooltipPicker.addEventListener('focus', () =>
        self.repositionTooltip(tooltipPicker)
      )
    })
    tooltipPickerLabels.forEach(function (pickerLabel, index) {
      pickerLabel.addEventListener('focus', () =>
        self.repositionTooltip(pickerLabel.parentNode)
      )
    })
    if (
      this.props.domains &&
      (typeof this.props.domains.loaded === 'undefined' ||
        this.props.domains.loaded === false)
    ) {
      this.props.dispatch(fetchItems('domains'))
    }
  }

  componentWillReceiveProps(props) {
    this.setState({ locales: props.locales.filter((key) => key !== 'en-US') })
  }

  repositionTooltip = (ele) => {
    // get bounding box of this button
    const rect = ele.getBoundingClientRect()
    // get toolbar ele and it's bounding box
    const tEle = ele.closest('.ql-toolbar')
    const tRect = tEle.getBoundingClientRect()
    // calculate distance to left and right
    const diffLeft = rect.x - tRect.x
    const diffRight = tRect.x + tRect.width - (rect.x + rect.width)
    if (diffLeft >= diffRight) {
      // we're further to the right side of the toolbar - position tooltip to the left
      if (!ele.className.match(/(?:^|\s)tooltip-left(?!\S)/)) {
        ele.className += ' tooltip-left'
      }
    } else {
      ele.className = ele.className.replace(/(?:^|\s)tooltip-left(?!\S)/g, '')
    }
  }

  attachQuillRefs = (idx) => {
    // Ensure React-Quill reference is available:
    if (
      !this.reactQuillRefs[idx] ||
      typeof this.reactQuillRefs[idx].getEditor !== 'function'
    )
      return

    const quillRef = this.reactQuillRefs[idx].getEditor()
    if (quillRef !== null) this.quillRef = quillRef
  }

  imageHandler = () => {
    this.attachQuillRefs(this.state.activeTabIndex)
    this.cursorIdx = this.quillRef.getSelection().index
    this.fileStackRef.click()
  }

  pdfHandler = () => {
    this.attachQuillRefs(this.state.activeTabIndex)
    this.cursorIdx = this.quillRef.getSelection().index
    this.fileStackPDFRef.click()
  }

  cardHandler = () => {
    this.attachQuillRefs(this.state.activeTabIndex)
    let selection = this.quillRef.getSelection()
    if (selection.length > 0) {
      let selectedText = this.quillRef.getText(
        selection.index,
        selection.length
      )
      // TODO if selected text is already a card link,  we can just edit it somehow
      this.setState({
        showCardInput: true,
        selection: selection,
        selectedText: selectedText
      })
    } else {
      this.props.dispatch(addToast('Select some text for the link first.'))
    }
  }

  handleInsertLink = () => {
    const cardUrl = this.cardInputRef.value
    const { selectedText, selection } = this.state
    if (cardUrl.length > 0) {
      const validUrl = this.validateInsertLink(cardUrl)
      if (!validUrl) {
        this.showCardInputError()
      } else {
        const insertCode = `[hf-card-link="${validUrl}"]${selectedText}[/hf-card-link]`
        this.quillRef.deleteText(selection.index, selection.length)
        this.quillRef.insertText(selection.index, insertCode)
        this.closeCardInput()
        this.quillRef.setSelection(selection.index + insertCode.length)
        this.quillRef = null
      }
    } else {
      this.showCardInputError()
    }
  }

  validateInsertLink = (url) => {
    let urlBase = [process.env.REACT_APP_BASE_URL]
    // make sure base url is correct
    if (urlBase[0] === 'https://v2.hostfully.com') {
      urlBase[1] = 'https://hostful.ly'
    }
    // add domains to these url bases
    if (this.props.domains.loaded && this.props.domains.data.length > 0) {
      for (var d = 0; d < this.props.domains.data.length; d++) {
        var customDomain = `https://${this.props.domains.data[d].domain}`
        urlBase.push(customDomain)
      }
    }
    let returnValue = false
    for (var i = 0; i < urlBase.length; i++) {
      if (url.substring(0, urlBase[i].length) === urlBase[i]) {
        // now strip off the base url and a trailing slash.
        const urlWithoutBase = url.substring(urlBase[i].length + 1)
        if (urlWithoutBase) {
          const slashIdx = urlWithoutBase.indexOf('/')
          if (slashIdx !== -1) {
            if (urlWithoutBase.substring(slashIdx).length > 0) {
              returnValue = urlWithoutBase.substring(slashIdx)
            }
          }
        }
      }
    }
    return returnValue
  }

  handleCardInputChange = () => {
    if (this.validateInsertLink(this.cardInputRef.value)) {
      this.clearCardInputError()
    } else {
      this.showCardInputError()
    }
  }

  showCardInputError = () => {
    this.setState({ showCardInputError: true })
  }
  clearCardInputError = () => {
    this.setState({ showCardInputError: false })
  }
  closeCardInput = () => {
    this.setState({ showCardInput: false, selection: null, selectedText: null })
  }

  varHandler = () => {
    this.attachQuillRefs(this.state.activeTabIndex)
    let selection = this.quillRef.getSelection()
    this.setState({ showVarInput: true, selection: selection })
  }

  handleInsertVar = () => {
    const whichVar = this.varSelectRef.value
    const fallback = this.varInputRef.value
    const { selection } = this.state
    if (whichVar.length > 0) {
      const insertCode = `[hf-custom-variable="${whichVar}"]${fallback}[/hf-custom-variable]`
      this.quillRef.insertText(selection.index, insertCode)
      this.closeVarInput()
      this.quillRef.setSelection(selection.index + insertCode.length)
      this.quillRef = null
    }
  }

  showVarInputError = () => {
    this.setState({ showVarInputError: true })
  }
  clearVarInputError = () => {
    this.setState({ showVarInputError: false })
  }
  closeVarInput = () => {
    this.setState({ showVarInput: false, selection: null })
  }

  handleManagedControlChange = (value) => {
    const self = this
    if (typeof this.props.onChange === 'function') {
      self.props.onChange(value)
    }
  }

  imageUploaded(uploadResult) {
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      fixRotation(filestackUrl).then((data) => {
        if (data.url && data.url.length > 0) {
          this.quillRef.insertEmbed(this.cursorIdx, 'image', data.url)
          this.quillRef.setSelection(this.cursorIdx + 1)
          this.quillRef = null
          this.cursorIdx = 0
        }
      })
    }
  }

  pdfUploaded(uploadResult) {
    if (uploadResult.filesUploaded && uploadResult.filesUploaded.length > 0) {
      const file = uploadResult.filesUploaded[0]
      const filestackUrl = file.url
      // TODO embed a document or shortcode here instead
      const insertCode = `[hf-pdf="${filestackUrl}"][/hf-pdf]`

      this.quillRef.insertText(this.cursorIdx, insertCode)
      this.quillRef.setSelection(this.cursorIdx + insertCode.length)
      this.quillRef = null
      this.cursorIdx = 0
    }
  }

  handleTabChange = (activeTabIndex) => {
    this.setState({ activeTabIndex })
  }

  handleEnter = (e) => {
    if (this.props.suppressEnter) {
      e.stopPropagation()
    }
  }

  autoTranslateText = () => {
    const selectedLocale = this.state.locales[this.state.activeTabIndex - 1]
    const text = this.props.edit_model[this.props.field]
    const from_lang = 'en'
    const to_lang = autoTranslateCodes[selectedLocale]

    const self = this
    translateText(text, from_lang, to_lang).then((new_text) => {
      self.updateFieldValue(new_text)
    })
  }

  updateFieldValue = (text) => {
    const { dispatch, model, txn_field } = this.props
    const selectedLocale = this.state.locales[this.state.activeTabIndex - 1]
    const field_str = `${model}.${txn_field}[${selectedLocale}]`
    setTimeout(() => {
      dispatch(actions.setValidity(field_str, { isValid: false }))
      setTimeout(() => {
        dispatch(actions.change(field_str, text))
        setTimeout(() => {
          dispatch(actions.setValidity(field_str, { isValid: true }))
        }, 400)
      }, 200)
    }, 200)
  }

  renderAutoTranslateLink = () => {
    if (
      !this.state.locales ||
      this.state.locales.length === 0 ||
      this.state.activeTabIndex === 0
    ) {
      return null
    } else {
      // only render if the translated text is empty so far
      const selectedLocale = this.state.locales[this.state.activeTabIndex - 1]
      // if we're not ignoring the model
      // AND we have a model, and a translation field, and there is nothing translated manually yet
      // AND we have something in the english field
      if (this.props.ignoreModel) {
        return null
      } else if (!this.props.edit_model) {
        return null
      } else if (
        this.props.edit_model[this.props.txn_field] &&
        typeof this.props.edit_model[this.props.txn_field] !== 'undefined' &&
        this.props.edit_model[this.props.txn_field][selectedLocale] &&
        fieldHasContent(
          this.props.edit_model[this.props.txn_field][selectedLocale]
        )
      ) {
        return null
      } else if (
        !this.props.edit_model[this.props.field] ||
        !fieldHasContent(this.props.edit_model[this.props.field])
      ) {
        return null
      } else {
        const { formatMessage } = this.props.intl
        const lm = localeMessages[selectedLocale]
        const label = formatMessage(lm)
        const buttonPadding = { margin: '12px' }
        return (
          <Button
            onClick={this.autoTranslateText}
            raised
            primary
            style={buttonPadding}
          >
            {`Auto-Translate to ${label}`}
          </Button>
        )
      }
    }
  }

  getModules = () => {
    const Quill = ReactQuill.Quill
    var Font = Quill.import('formats/font')
    Font.whitelist = [
      'Arial',
      'Verdana',
      'Tahoma',
      'Trebuchet',
      'Times',
      'Georgia',
      'Garamond',
      'Courier',
      'Brush'
    ]
    Quill.register(Font, true)
    Quill.register('modules/imageResize', ImageResize)

    if (this.props.minimalTools) {
      return {
        clipboard: {
          matchVisual: false
        },
        toolbar: {
          container: [
            [{ font: Font.whitelist }, 'bold', 'italic', 'underline'],
            ['blockquote', 'clean'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ color: [] }, { background: [] }] // dropdown with defaults from
          ]
        }
      }
    } else {
      return {
        clipboard: {
          matchVisual: false
        },
        imageResize: {
          parchment: Quill.import('parchment'),
          modules: ['Resize', 'DisplaySize']
        },
        toolbar: {
          container: [
            [{ font: Font.whitelist }, 'bold', 'italic', 'underline'],
            ['link', 'image', 'pdf'],
            ['blockquote', 'clean'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ color: [] }, { background: [] }], // dropdown with defaults from
            ['card']
          ],
          handlers: {
            image: this.imageHandler,
            pdf: this.pdfHandler,
            card: this.cardHandler
          }
        }
      }
    }
  }

  component = (props) => {
    const { idx, ...rest } = props
    // run value through nl2br to preserve legacy whitespace
    let useValue = props.value
    if (this.props.ignoreModel && useValue === '') {
      useValue = this.props.defaultValue
    }
    return this.props.simpleEditor ? (
      <TextFieldForm
        ref={(el) => {
          this.reactQuillRefs[idx] = el
        }}
        max_length={props.max_length}
        {...rest}
      />
    ) : (
      <ReactQuill
        {...props}
        ref={(el) => {
          this.reactQuillRefs[idx] = el
        }}
        theme="snow"
        modules={this.modules}
        value={nl2br(useValue)}
      />
    )
  }

  render() {
    const { formatMessage } = this.props.intl
    const self = this
    const { field, txn_field, max_length, rows, availableVariables } =
      this.props
    // if we have anything as availableVariables, show the variable button
    if (!this.props.minimalTools) {
      if (availableVariables && Object.keys(availableVariables).length) {
        this.modules.toolbar.container[6] = ['card', 'var']
        this.modules.toolbar.handlers.var = this.varHandler
      } else {
        this.modules.toolbar.container[6] = ['card']
      }
    }
    const filestackOptions = {
      accept: 'image/*',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      storeTo: {
        location: 'gcs'
      },
      imageMax: [800, 600],
      transformations: {
        crop: true,
        rotate: true
      }
    }
    const filestackPDFOptions = {
      accept: '.pdf',
      maxFiles: 1,
      fromSources: ['local_file_system', 'url'],
      storeTo: {
        location: 'gcs'
      }
    }
    const filestackKey = process.env.REACT_APP_FILESTACK_KEY
    const autoTranslateLink = this.renderAutoTranslateLink()

    const tabs = this.state.locales
      ? this.state.locales.map((locale, index) => {
          const field_str = '.' + txn_field + '[' + locale + ']'
          const lm = localeMessages[locale]
          const label = formatMessage(lm)
          const className = this.props.simpleEditor
            ? 'hf-multilang-editor'
            : 'hf-multilang-editor hf-textarea text-editor'
          return (
            <Tab label={label} key={locale}>
              {autoTranslateLink}
              <Control.text
                model={field_str}
                className={className}
                componentModel={field_str}
                idx={index + 1}
                component={this.component}
                max_length={max_length}
                rows={rows}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    return self.handleEnter(e)
                  }
                }}
                dir="auto"
              />
            </Tab>
          )
        })
      : null
    // Always want the default/en field
    const enField = '.' + field
    const filestackDialog = this.props.simpleEditor ? null : (
      <PickerOverlay
        apikey={filestackKey}
        actionOptions={filestackOptions}
        onSuccess={this.imageUploaded}
        customRender={({ onPick }) => (
          <div
            className=""
            ref={(input) => {
              self.fileStackRef = input
            }}
            onClick={onPick}
          />
        )}
      />
    )
    const filestackPDFDialog = this.props.simpleEditor ? null : (
      <PickerOverlay
        apikey={filestackKey}
        actionOptions={filestackPDFOptions}
        onSuccess={this.pdfUploaded}
        customRender={({ onPick }) => (
          <div
            className=""
            ref={(input) => {
              self.fileStackPDFRef = input
            }}
            onClick={onPick}
          />
        )}
      />
    )

    const cardInputDialog = this.props.simpleEditor ? null : (
      <DialogContainer
        id={`inputDialog`}
        visible={this.state.showCardInput}
        onHide={self.closeCardInput}
        title="Dynamic guidebook link"
        focusOnMount={true}
        containFocus={true}
        portal={true}
        lastChild={true}
        className="md-text-center"
      >
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--12 md-body-1">
            Paste a link to another guidebook card or section. We'll convert it
            to work on any guidebook using this card.
          </div>
          <TextField
            id="floating-center-title"
            label="Guidebook Card Link"
            ref={(input) => {
              self.cardInputRef = input
            }}
            lineDirection="center"
            placeholder="https://hostful.ly/gbwxoso/arrival"
            className="md-cell md-cell--12"
            error={this.state.showCardInputError}
            errorText={'Please enter a valid card URL'}
            onChange={self.handleCardInputChange}
          />
          <div className="md-cell md-cell--12">
            <Button onClick={this.handleInsertLink} raised primary>
              OK
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={self.closeCardInput} flat secondary>
              Cancel
            </Button>
          </div>
        </div>
      </DialogContainer>
    )
    // filter out blank variables
    const insertableVariables = this.props.availableVariables
      ? this.props.availableVariables.filter((item) => item.key !== '')
      : []
    const defaultVarValue = insertableVariables.length
      ? insertableVariables[0].key
      : ''
    const marginStyle = { marginTop: '18px' }
    const varInputDialog = this.props.simpleEditor ? null : (
      <DialogContainer
        id={`varDialog`}
        visible={this.state.showVarInput}
        onHide={self.closeVarInput}
        title="Insert Variable"
        focusOnMount={true}
        containFocus={true}
        portal={true}
        lastChild={true}
        className="md-text-center"
      >
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--12 md-body-1">
            Choose a variable to insert and enter some fallback text if you
            wish. (fallback text will be shown when this variable is not
            available).
          </div>
          <SelectField
            id="varSelection"
            label="Choose variable to insert"
            ref={(input) => {
              self.varSelectRef = input
            }}
            menuItems={insertableVariables}
            defaultValue={defaultVarValue}
            simplifiedMenu={false}
            placeholder="Select a variable"
            itemLabel="label"
            itemValue="key"
            className="md-cell md-cell--12 md-text-left"
          />
          <TextField
            id="varFallback"
            label="Fallback text"
            ref={(input) => {
              self.varInputRef = input
            }}
            lineDirection="center"
            placeholder="fallback text"
            className="md-cell md-cell--12"
          />
          <div className="md-cell md-cell--12" style={marginStyle}>
            <Button onClick={this.handleInsertVar} raised primary>
              OK
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button onClick={self.closeVarInput} flat secondary>
              Cancel
            </Button>
          </div>
        </div>
      </DialogContainer>
    )

    // if we're ignoring the model, set the value directly and wire up an onChange event
    const control = this.props.ignoreModel ? (
      <Control.text
        model={enField}
        className="hf-multilang-editor"
        componentModel={enField}
        required={this.props.required}
        idx={0}
        component={this.component}
        max_length={max_length}
        rows={rows}
        onChange={(value) => {
          self.handleManagedControlChange(value)
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            return self.handleEnter(e)
          }
        }}
      />
    ) : (
      <Control.text
        model={enField}
        className="hf-multilang-editor"
        componentModel={enField}
        required={this.props.required}
        idx={0}
        component={this.component}
        max_length={max_length}
        rows={rows}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            return self.handleEnter(e)
          }
        }}
      />
    )

    if (tabs) {
      tabs.unshift(
        <Tab label="English" key="en-US">
          {filestackDialog}
          {filestackPDFDialog}
          {cardInputDialog}
          {varInputDialog}
          {control}
        </Tab>
      )

      return (
        <TabsContainer
          className="hf-multilang-tabs hf-wider-selection-control-container"
          onTabChange={this.handleTabChange}
          activeTabIndex={this.state.activeTabIndex}
          themed
        >
          <Tabs tabId="tab" className="hf-scrolling-tabs" mobile={true}>
            {tabs}
          </Tabs>
        </TabsContainer>
      )
    } else {
      return (
        <div className="hf-wider-selection-control-container">
          {filestackDialog}
          {filestackPDFDialog}
          {cardInputDialog}
          {varInputDialog}
          {control}
        </div>
      )
    }
  }
}

MultilangWysiwyg.propTypes = propTypes
MultilangWysiwyg.defaultProps = defaultProps

function mapStateToProps(state, props) {
  const edit_model = props.model ? state[props.model] : null
  const listData = state.list

  return {
    edit_model,
    domains: listData.domains
  }
}

export default connect(mapStateToProps)(injectIntl(MultilangWysiwyg))
