import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Avatar, FocusContainer, Switch } from 'react-md'
import { connect } from 'react-redux'
import {
  attachPropertyToGuidebook,
  autoCreateTokens,
  createListingFromProperty,
  synchTokens
} from 'redux/modules/orbirental/connect'
import { addToast } from 'redux/modules/toast'
import TooltipFontIcon from 'components/TooltipFontIcon'
import { fuzzyFilter, sortData } from 'utils/Data'
import { formatDate } from 'utils/Dates'

const propTypes = {
  item: PropTypes.object.isRequired,
  guidebooks: PropTypes.object.isRequired,
  limitReached: PropTypes.bool,
  // updateGB: PropTypes.func,
  showChooser: PropTypes.func
}

class PropertyListItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDropDown: false,
      selectedGB: null,
      showBuildLink: true,
      connectedName:
        props.item && props.item.gb_name ? `${props.item.gb_name}` : '',
      connectedLabel:
        props.item && props.item.gb_name
          ? `${props.item.gb_name} (${props.item.key})`
          : '',
      editID: props.item ? props.item.id : null,
      createTokens: props.item && props.item.create_tokens ? true : false
    }
    // this.handleChangeCreateTokens = this.handleChangeCreateTokens.bind(this);
  }

  showAttachDropdown = (property_uid, e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ showDropDown: true })
  }

  hideAttachDropdown = () => {
    this.setState({ showDropDown: false, selectedGB: null })
  }

  handleGbSelection = (value) => {
    this.setState({ selectedGB: value })
  }

  attachToGB = () => {
    const self = this
    attachPropertyToGuidebook(
      this.props.item.property_uid,
      this.state.selectedGB
    ).then((data) => {
      // now fetch the item normally
      const newLabel = `${data.name} (${data.key})`
      self.setState({
        connectedLabel: newLabel,
        connectedName: data.name,
        selectedGB: null,
        showDropDown: false,
        editID: data.id
      })
    })
  }

  createListing = () => {
    const self = this;
    self.setState({showBuildLink: false}, () => {
      createListingFromProperty(
        this.props.item.property_uid
      ).then((data) =>{
        self.props.dispatch(addToast('Book again listing created'))
      })
    })
  }

  editGB = () => {
    const url = `/host/guidebooks/${this.state.editID}`
    window.open(url, '_blank')
  }

  openProperty = (uid) => {
    const pmpBaseUrl = process.env.REACT_APP_PMP_URL
    const url = `${pmpBaseUrl}/app/#/property?uid=${uid}`;
    window.open(url, '_blank');
  }

  getDefaultValueForGbLink = (item) => {
    if (this.state.connectedName) {
      return this.state.connectedName
    } else {
      // see if we have a guidebook that matches the property name exactly
      if (this.props.guidebooks.data) {
        // console.log(this.props.guidebooks.data);
        let match = this.props.guidebooks.data.find(function (gb) {
          return gb.name === item.name
        })
        return match ? match.name : item.name
      }
      return ''
    }
  }

  handleChangeCreateTokens = (value) => {
    // save in the background and set the state on return
    const guidebook_id = this.state.editID
    const property_uid = this.props.item.property_uid
    this.setState({ createTokens: value }, () => {
      autoCreateTokens(guidebook_id, value)
      if (value === true) {
        synchTokens(property_uid)
      }
    })
  }

  completeWithFuzzyFilter = (data, filterText, dataLabel) => {
    const matches = fuzzyFilter(data, 'guidebooks', ['label'], filterText)
    return matches
  }

  render() {
    const self = this
    const { guidebooks, item, limitReached, showChooser } = this.props
    // if id is set then this is linked to a guidebook
    const avatarLink = this.state.editID ? (
      <Avatar src={item.image} alt="" onClick={self.editGB} />
    ) : (
      <Avatar src={item.image} alt="" />
    )

    const actions = []
    if (this.state.editID && !this.state.showDropDown) {
      actions.push(
        <div
          className="md-cell md-cell--1 md-cell--middle hf-relative"
          key={`view_gb_${item.property_uid}`}
        >
          <TooltipFontIcon
            tooltipLabel="View/Edit your Guidebook"
            tooltipPosition="top"
            className="hf-clickable"
            onClick={self.editGB}
          >
            pageview
          </TooltipFontIcon>
        </div>
      )
    } else if (!this.state.showDropDown) {
      actions.push(
        <div
          className="md-cell md-cell--1 md-cell--middle hf-relative"
          key={`view_gb_${item.property_uid}`}
        >
          <TooltipFontIcon
            tooltipLabel="Import from PMP"
            tooltipPosition="top"
            className="hf-clickable"
            onClick={(e) => {
              showChooser(item, e)
            }}
            disabled={limitReached}
          >
            system_update_alt
          </TooltipFontIcon>
        </div>
      )
    }
    if (this.state.showDropDown) {
      const gbSelections = []
      // console.log(this.props.guidebooks);
      if (guidebooks.data && guidebooks.data.length) {
        const gbData = sortData(guidebooks.data, 'name', 'asc')
        for (var i = 0, len = gbData.length; i < len; i++) {
          const gb = gbData[i]
          // only add guidebooks that are not already mapped to the list
          gbSelections.push({ label: `${gb.name}`, value: gb.id, id: gb.id })
        }
      }
      actions.push(
        <FocusContainer
          className="md-cell md-cell--8 md-cell--middle hf-relative"
          key={`select_gb_${item.property_uid}`}
          focusOnMount
        >
          <Autocomplete
            id={`select_gb_auto_${item.property_uid}`}
            data={gbSelections}
            dataLabel="label"
            dataValue="value"
            fullWidth
            showUnfilteredData
            filter={this.completeWithFuzzyFilter}
            defaultValue={this.getDefaultValueForGbLink(item)}
            onAutocomplete={(val) => {
              self.handleGbSelection(val)
            }}
            label="Select a guidebook"
          />
        </FocusContainer>
      )
      if (this.state.selectedGB) {
        actions.push(
          <div
            className="md-cell md-cell--1 md-cell--middle hf-relative"
            key={`save_${item.property_uid}`}
          >
            <TooltipFontIcon
              tooltipLabel="Save"
              tooltipPosition="top"
              className="hf-clickable"
              color="#006400"
              onClick={self.attachToGB}
            >
              save
            </TooltipFontIcon>
          </div>
        )
      }
      actions.push(
        <div
          className="md-cell md-cell--1 md-cell--middle hf-relative"
          key={`cancel_${item.property_uid}`}
        >
          <TooltipFontIcon
            tooltipLabel="Cancel"
            tooltipPosition="top"
            className="hf-clickable"
            color="#8B0000"
            onClick={(e) => {
              self.hideAttachDropdown()
            }}
          >
            close
          </TooltipFontIcon>
        </div>
      )
    } else {
      if (this.state.showBuildLink) {
        actions.push(
          <div
            className="md-cell md-cell--1 md-cell--middle hf-relative"
            key={`build_${item.property_uid}`}
          >
            <TooltipFontIcon
              tooltipLabel="Build Listing"
              tooltipPosition="top"
              className="hf-clickable"
              color="#8B0000"
              onClick={self.createListing}
            >
              location_on
            </TooltipFontIcon>
          </div>
        )
      }
      actions.push(
        <div
          className="md-cell md-cell--1 md-cell--middle hf-relative"
          key={`attach_${item.property_uid}`}
        >
          <TooltipFontIcon
            tooltipLabel="Attach to existing Guidebook"
            tooltipPosition="top"
            className="hf-clickable"
            onClick={(e) => {
              self.showAttachDropdown(item.property_uid, e)
            }}
          >
            link
          </TooltipFontIcon>
        </div>
      )
      actions.push(
        <div
          className="md-cell md-cell--6 md-cell--middle hf-relative"
          key={`tokens_${item.property_uid}`}
        >
          <Switch
            id={`create_tokens_${item.property_uid}`}
            name={`create_tokens_${item.property_uid}`}
            disabled={!this.state.editID}
            checked={this.state.createTokens}
            onChange={(value) => {
              self.handleChangeCreateTokens(value)
            }}
            label={`Auto-create secure links`}
          />
        </div>
      )
    }
    let connected = null
    if (item.connected_at) {
      const initConnectedDate = new Date(item.connected_at)
      const connectedDate = new Date(
        initConnectedDate.getFullYear(),
        initConnectedDate.getMonth(),
        initConnectedDate.getDate()
      )
      connected = `Connected: ${formatDate(connectedDate, false)}`
    }
    const helpText = this.state.editID ? (
      <span>
        Imported as <strong className="md-text--theme-secondary">{this.state.connectedLabel}</strong>
        <br />
        {connected}
      </span>
    ) : (
      <span>Not imported yet</span>
    )

    return (
      <div className="md-cell md-cell--12">
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--1">{avatarLink}</div>
          <div className="md-cell md-cell--5">
            <div>
              <strong>{item.name}</strong>
              <br/>
              <span className="md-text--theme-tertiary hf-link" onClick={() => {this.openProperty(item.property_uid)}} >{item.property_uid}</span>
            </div>
            <div>{helpText}</div>
          </div>
          <div className="md-cell md-cell--6">
            <div className="md-grid">{actions}</div>
          </div>
        </div>
      </div>
    )
  }
}

PropertyListItem.propTypes = propTypes

export default connect()(PropertyListItem)
