import Breakjs from 'breakjs'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'

import GuidebookMap from 'components/GuidebookMap'
import MapRecOverlay from 'components/MapRecOverlay'
import MapListingOverlay from 'components/MapListingOverlay'
import { OnAtLeastDesktop } from 'components/ScreenSize'
import { setMapBounds, updateVisibleMapMarkerIds } from 'redux/modules/map'
import {trackGuidebookView} from 'utils/GTM';

import MainTabs from './components/MainTabs'

class MainView extends Component {
  constructor(props) {
    super(props)
    this.onBoundsChanged = this.onBoundsChanged.bind(this)
    this.visibleMarkersChange = this.visibleMarkersChange.bind(this)
    this.handleRecClick = this.handleRecClick.bind(this)
    this.handleListingClick = this.handleListingClick.bind(this)
    this.guidebookViewRecorded = null
    this.state = {
      category: null,
      bounds: null,
      recDisplayed: null,
      listingDisplayed: null
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.guidebookViewRecorded !== this.props.guidebook.key &&
      this.props.guidebook.key &&
      this.props.guidebook.key !== 'loading' &&
      this.props.guidebook.host_user_id
    ) {
      this.guidebookViewRecorded = this.props.guidebook.key
      // console.log(this.props.guidebook);
      trackGuidebookView(this.props.guidebook.id, this.props.guidebook.host_user_id);
    }
    // update the map settings
    var params = this.context.router.route.match.params
    const urlCategory = params.activeTab || params.category || 'default'
    const { category } = this.state
    const { map } = this.props
    if (category !== urlCategory) {
      const bounds = map.bounds[urlCategory]
      this.setState({
        category: urlCategory,
        bounds
      })
    }
  }

  onBoundsChanged(mapProps, map) {
    const { dispatch } = this.props
    const { category } = this.state
    const center = map.getCenter()
    const zoom = map.getZoom()
    dispatch(setMapBounds(category, center.lat(), center.lng(), zoom))
  }

  visibleMarkersChange(visibleMarkerIds) {
    const { dispatch } = this.props
    const { category } = this.state
    dispatch(updateVisibleMapMarkerIds(category, visibleMarkerIds))
  }

  handleRecClick = (rec, e) => {
    if (!this.state.recDisplayed && !this.state.listingDisplayed) {
      this.setState({ recDisplayed: rec, listingDisplayed: null })
    }
  }

  handleCloseRec = (e) => {
    this.setState({ recDisplayed: null, listingDisplayed: null })
  }

  handleListingClick = (listing, e) => {
    if (!this.state.listingDisplayed && !this.state.recDisplayed) {
      this.setState({ listingDisplayed: listing, recDisplayed: null })
    }
  }

  handleCloseListing = (e) => {
    this.setState({ listingDisplayed: null, recDisplayed: null })
  }

  renderMapRecOverlay = () => {
    if (this.state.recDisplayed) {
      const gbSlim = {
        key: this.props.guidebook.key,
        host_user_id: this.props.guidebook.host_user_id
      }
      const breakLayout = Breakjs({
        mobile: 0,
        phablet: 550,
        superwide: 1500
      })
      let overlayClass = 'hf-mainview-map-overlay'
      if (breakLayout.atLeast('superwide')) {
        overlayClass = overlayClass + ' nav-open'
      }
      return (
        <MapRecOverlay
          className={overlayClass}
          guidebook={gbSlim}
          rec={this.state.recDisplayed}
          recsOnly={this.props.recsOnly}
          onClose={this.handleCloseRec}
        />
      )
    }
    return null
  }

  renderMapListingOverlay = () => {
    if (this.state.listingDisplayed) {
      const gbSlim = {
        key: this.props.guidebook.key,
        host_user_id: this.props.guidebook.host_user_id
      }
      const breakLayout = Breakjs({
        mobile: 0,
        phablet: 550,
        superwide: 1500
      })
      let overlayClass = 'hf-mainview-map-overlay'
      if (breakLayout.atLeast('superwide')) {
        overlayClass = overlayClass + ' nav-open'
      }
      return (
        <MapListingOverlay
          className={overlayClass}
          guidebook={gbSlim}
          listing={this.state.listingDisplayed}
          onClose={this.handleCloseListing}
        />
      )
    }
    return null
  }

  render() {
    const { category, bounds } = this.state
    const { visibleMarkerIdsByCategory } = this.props
    // if the map is visible, only show markers that are visible on the map
    const visibleMarkerIds = this.refs.mapHolder
      ? visibleMarkerIdsByCategory[category]
      : null
    return (
      <div className="main-desktop">
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--6 md-cell--8-tablet">
            <div id="hf-main-tabs">
              <div>
                <MainTabs
                  guidebook={this.props.guidebook}
                  category={category}
                  visibleMarkerIds={visibleMarkerIds}
                  recsOnly={this.props.recsOnly}
                  onRecClick={this.handleRecClick}
                  onListingClick={this.handleListingClick}
                  {...this.props}
                />
              </div>
            </div>
          </div>
          <OnAtLeastDesktop>
            <div
              ref="mapHolder"
              className="md-cell md-cell--6"
              id="map-outer-container"
            >
              <div className="map-holder-fixed">
                <GuidebookMap
                  lat={bounds ? bounds.lat : undefined}
                  lng={bounds ? bounds.lng : undefined}
                  initialZoom={bounds ? bounds.zoom : undefined}
                  className="map-container-fixed"
                  fitType={bounds ? 'bounds' : 'smart'}
                  onBoundsChanged={this.onBoundsChanged}
                  onVisibleMarkersChange={this.visibleMarkersChange}
                  guidebook={this.props.guidebook}
                  fixToPosition
                />
              </div>
              {this.renderMapRecOverlay()}
              {this.renderMapListingOverlay()}
            </div>
          </OnAtLeastDesktop>
        </div>
      </div>
    )
  }
}

MainView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  recsOnly: PropTypes.bool
}
MainView.contextTypes = {
  router: PropTypes.object
}
MainView.defaultProps = {
  recsOnly: false
}

function mapStateToProps(state, props) {
  return {
    guidebook: state.guidebook.data,
    map: state.map,
    visibleMarkerIdsByCategory: state.map.visibleMarkerIdsByCategory
  }
}

export default connect(mapStateToProps)(injectIntl(MainView))
