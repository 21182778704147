import PropTypes from 'prop-types'

export const addressShape = PropTypes.shape({
  // these are pretty self - explanitory
  street_number: PropTypes.string,
  street: PropTypes.string,
  state: PropTypes.string,
  // prefix is where we store unit/apartment number
  // but could also be used for other things
  prefix: PropTypes.string,
  post_code: PropTypes.string,
  // locality is city generally
  locality: PropTypes.string,
  lng: PropTypes.number,
  lat: PropTypes.number,
  country_code: PropTypes.string
})

export const directionsShape = PropTypes.shape({
  // whether to show this section at all
  label: PropTypes.string,
  // whether to show the to/from airport section
  show_airport: PropTypes.bool,
  // text of the airport section
  airport_text: PropTypes.string,
  // object containing translations for airport text
  airport_text_txn: PropTypes.object,

  // whether to show the taxi section
  show_taxi: PropTypes.bool,
  // text of the taxi section
  taxi_text: PropTypes.string,
  // object containing translations for taxi text
  taxi_text_txn: PropTypes.object,

  // whether to show uber section
  show_uber: PropTypes.bool,
  // text of the uber section
  uber_text: PropTypes.string,
  // object containing translations for uber text
  uber_text_txn: PropTypes.object,
  // voucher code for uber referrals
  uberpool_voucher: PropTypes.string,

  // whether to show the lyft section
  show_lyft: PropTypes.bool,
  // text of the lyft section
  lyft_text: PropTypes.string,
  // object containing translations for lyft text
  lyft_text_txn: PropTypes.object,
  // voucher code for lyft referrals
  lyft_voucher: PropTypes.string,

  // whether to show the bus section
  show_bus: PropTypes.bool,
  // text of the bus section
  bus_text: PropTypes.string,
  // object containing translations for bus text
  bus_text_txn: PropTypes.object,

  // whether to show train section (might be subway in some doco)
  show_train: PropTypes.bool,
  // text of the train section
  train_text: PropTypes.string,
  // object containing translations for train text
  train_text_txn: PropTypes.object,

  // pickup section
  show_pickup: PropTypes.bool,
  // text of the pickup section
  pickup_text: PropTypes.string,
  // object containing translations for pickup text
  pickup_text_txn: PropTypes.object,

  // driving section
  show_driving: PropTypes.bool,
  // text of the driving section
  driving_text: PropTypes.string,
  // object containing translations for driving text
  driving_text_txn: PropTypes.object,

  // additional custom directions
  additional_directions: PropTypes.string,
  additional_directions_txn: PropTypes.object
})

export const parkingShape = PropTypes.shape({
  // some statically defined parking situations
  on_street_available: PropTypes.bool,
  expensive_nearby: PropTypes.bool,
  economical_available: PropTypes.bool,
  driveway_available: PropTypes.bool,

  // additional custom parking text
  parking_text: PropTypes.string,
  // translations of additional parking text
  parking_text_txn: PropTypes.object
  // this field isn't reliable, don't use it
  //show_parking_details: PropTypes.bool,
})

export const checkoutShape = PropTypes.shape({
  // checkout time eg 11:00am
  checkout_time: PropTypes.string,
  // custom text description for checkout
  checkout_text: PropTypes.string,
  // translations for checkout text
  checkout_text_txn: PropTypes.object,
  // options for late checkout
  show_late_checkout: PropTypes.bool,
  late_checkout_sometimes: PropTypes.bool,
  late_checkout_no: PropTypes.bool,
  late_checkout_contact: PropTypes.bool,

  // lots of checkboxes for what to do before checking out
  store_bags_after_checkout: PropTypes.bool,
  sign_guestbook: PropTypes.bool,
  // `cleanup` checkboxes (show below line)
  towels_on_floor: PropTypes.bool,
  towels_in_tub: PropTypes.bool,
  take_out_trash: PropTypes.bool,
  rearrange_furniture: PropTypes.bool,
  lock_doors: PropTypes.bool,
  key_lockbox: PropTypes.bool,
  key_inside: PropTypes.bool,
  electrical_off: PropTypes.bool,
  door_unlocked: PropTypes.bool,
  dishes_in_washer: PropTypes.bool,
  clean_dishes: PropTypes.bool,
  beds_unmade: PropTypes.bool
})

export const hostInfoShape = PropTypes.shape({
  host_email: PropTypes.string,
  host_name: PropTypes.string,
  label: PropTypes.string,
  image: PropTypes.string,
  host_phone: PropTypes.arrayOf(PropTypes.string),
  host_intro: PropTypes.string,
  host_intro_txn: PropTypes.object
})

export const wifiShape = PropTypes.shape({
  //wifi can be provided or not
  wifi_provided: PropTypes.bool.isRequired,

  //if wifi_provided === true fields:
  //some hosts don't include wifi creds in card (online) they write it down in the property (offline):
  online: PropTypes.bool,
  offline: PropTypes.bool,

  //if online === true fields:
  network_name: PropTypes.string,
  wifi_password: PropTypes.string,

  //if offline === true: (tell guest where to find credentials in property)
  wifi_offline: PropTypes.string,

  //how to handle wifi issues (shown if wifi_provided)
  if_issues_reset: PropTypes.bool,
  if_issues_cafe: PropTypes.bool,
  if_issues_alt: PropTypes.bool, //call me

  //if if_issues_reset === true:
  reset_text: PropTypes.string,
  reset_text_txn: PropTypes.object,

  //show if if_issues_cafe (OR use_external_cafe below)
  cafe_address: PropTypes.string,

  //if Wifi provided === false fields:
  use_external_cafe: PropTypes.bool,
  use_external_network: PropTypes.bool,
  no_wifi: PropTypes.bool,

  //if use_external_cafe reuse cafe_address field above

  //show if use_external_network
  external_network_name: PropTypes.string,

  //always allow user to enter value in this field regardless of choices above
  wifi_rules_text: PropTypes.string,
  wifi_rules_text_txn: PropTypes.object
})
export const checkinShape = PropTypes.shape({
  show_early: PropTypes.bool,
  self_access_meet: PropTypes.bool,
  self_access_lockbox: PropTypes.bool,
  self_access_keyless: PropTypes.bool,
  self_access_email: PropTypes.bool,
  self_access_codes: PropTypes.bool,
  id: PropTypes.number,
  early_checkin_text: PropTypes.string,
  early_checkin_text_txn: PropTypes.object,
  early_checkin_sometimes: PropTypes.bool,
  early_checkin_never: PropTypes.bool,
  early_checkin_alternative: PropTypes.bool,
  checkin_time: PropTypes.string,
  checkin_end_time: PropTypes.string,
  use_checkin_end_time: PropTypes.bool,
  checkin_text: PropTypes.string,
  checkin_text_txn: PropTypes.object,
  bag_storage_allowed: PropTypes.bool,
  access_image: PropTypes.string,
  login_provided_offline: PropTypes.bool,
  login_offline_text: PropTypes.string,
  login_offline_text_txn: PropTypes.object
})

export const houseRulesInformationShape = PropTypes.shape({
  name: PropTypes.string,
  title: PropTypes.string,
  title_txn: PropTypes.object,
  content: PropTypes.string,
  content_txn: PropTypes.object,
  icon: PropTypes.string
})

export const categoryShape = PropTypes.shape({
  description: PropTypes.string,
  description_txn: PropTypes.object,
  icon: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string
})

export const recommendationShape = PropTypes.shape({
  address: addressShape,
  category: categoryShape,
  content: PropTypes.string,
  content_txn: PropTypes.object,
  id: PropTypes.number,
  image: PropTypes.string,
  key: PropTypes.string,
  name: PropTypes.string,
  name_txn: PropTypes.object,
  sponsor_cta: PropTypes.string,
  sponsor_cta_txn: PropTypes.object,
  sponsor_text: PropTypes.string,
  sponsor_text_txn: PropTypes.object,
  sponsored: PropTypes.bool,
  website: PropTypes.string,
  why_recommended: PropTypes.string,
  why_recommended_txn: PropTypes.object
})

export const listingShape = PropTypes.shape({
  address: addressShape,
  description: PropTypes.string,
  description_txn: PropTypes.object,
  id: PropTypes.number,
  image: PropTypes.string,
  name: PropTypes.string,
  name_txn: PropTypes.object,
  booking_link: PropTypes.string
})

export const marketplaceItemShape = PropTypes.shape({
  cancellationPolicy: PropTypes.string,
  cateredToType: PropTypes.string,
  dailyRate: PropTypes.number,
  defaultPicture: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.number,
  guidebooks: PropTypes.array,
  hourlyRate: PropTypes.number,
  isActive: PropTypes.bool,
  partyMaximumSize: PropTypes.number,
  price: PropTypes.number,
  serviceProviderUid: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  uid: PropTypes.string
})

export const themeShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  logo: PropTypes.string,
  website: PropTypes.string
})

export const domainShape = PropTypes.shape({
  id: PropTypes.number,
  domain: PropTypes.string,
  status: PropTypes.string
})

export const userShape = PropTypes.shape({})

export const houseRulesShape = PropTypes.arrayOf(houseRulesInformationShape)

export const guidebookShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  key: PropTypes.string,
  image: PropTypes.string,
  address: addressShape,
  checkin: checkinShape,
  checkout: checkoutShape,
  directions: directionsShape,
  domain: domainShape,
  home_label: PropTypes.string,
  host_intro: hostInfoShape,
  informations: houseRulesShape,
  locales: PropTypes.arrayOf(PropTypes.string),
  parking: parkingShape,
  recommendations: PropTypes.arrayOf(recommendationShape),
  theme: themeShape,
  wifi: wifiShape
})
