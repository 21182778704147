import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { replace } from 'react-router-redux'
import { Avatar, FontIcon, ListItem } from 'react-md'
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc'

import EmptyState from '../../EmptyState'
import { ucFirst } from 'utils/Strings'
import { resizeImage } from 'utils/Image'

class ManageListingsSelectedTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 1,
      filter: ''
    }
    this.onSortEnd = this.onSortEnd.bind(this)
  }

  editItemFromGuidebook(id) {
    const { dispatch, ownerId, ownerSingularName, ownerPluralName } = this.props
    // BA. make sure that we don't reload/refresh the guidebook/template when going off to edit a card
    dispatch(actions.change(`edit_${ownerSingularName}.noReload`, true))
    let url = `/host/${ownerPluralName}/${ownerId}/listings/${id}`
    dispatch(replace(url))
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { selected_listings, singularName, dispatch } = this.props
    const ordered_listings = arrayMove(selected_listings, oldIndex, newIndex)
    dispatch(
      actions.change('edit_' + singularName + '.listings', ordered_listings)
    )
  }

  formatSecondary = (listing) => {
    let secondLine = null
    if (
      listing.address &&
      listing.address.locality &&
      listing.address.street &&
      listing.address.post_code
    ) {
      secondLine = `${listing.address.street_number ? listing.address.street_number + ' ' : ''}${listing.address.street}, ${listing.address.locality} (${listing.address.post_code})`
    } else if (listing.address && listing.address.locality && listing.address.street) {
      secondLine = `${listing.address.street}, ${listing.address.locality}`
    } else if (listing.address && listing.address.locality) {
      secondLine = listing.address.locality
    } else {
      secondLine = 'Locationless Recommendation'
    }
    if (listing.external_provider && listing.external_id) {
      const providerLabel = listing.external_provider
        ? ucFirst(listing.external_provider)
        : 'unknown provider'
      const providerRef = listing.external_id
      const providerSpan = listing.external_provider ? (
        <span className="item-list-sponsored">
          <FontIcon>loyalty</FontIcon> {providerLabel}
        </span>
      ) : null
      const idSpan = listing.external_id ? (
        <span className="item-list-sponsored">
          <FontIcon>pin</FontIcon> {providerRef}
        </span>
      ) : null
      return (
        <div>
          <span>{secondLine}</span>
          <br />
          {providerSpan} {idSpan}
        </div>
      )
    }
    return secondLine
  }

  render() {
    const self = this
    const { active, selected_listings } = this.props
    const SortableItem = SortableElement(({ listing }) => {
      const avatarColor =  { borderColor: '573c0a'}
      const resizedImage = resizeImage(listing.image, 50, 50, true)
      return (
        <ListItem
          key={listing.id}
          className="hf-listingslist-item"
          leftAvatar={
            <Avatar
              src={resizedImage}
              className="hf-listingslist-item hf-hideOnLessThanPhablet"
              onClick={(e) => {
                self.editItemFromGuidebook(listing.id)
              }}
              style={avatarColor}
              alt=""
            >
              {listing.name.charAt(0).toUpperCase()}
            </Avatar>
          }
          primaryText={listing.name}
          secondaryText={self.formatSecondary(listing)}
          rightIcon={<FontIcon>reorder</FontIcon>}
          threeLines
        />
      )
    })

    const SortableList = SortableContainer(({ listings }) => {
      return (
        <div>
          {listings.map((listing, index) => (
            <SortableItem key={`item-${index}`} index={index} listing={listing} />
          ))}
        </div>
      )
    })

    return (
      <div className="md-grid">
        {active &&
          (selected_listings.length > 0 ? (
            <div className="md-cell md-cell--12">
              <SortableList
                listings={selected_listings}
                onSortEnd={this.onSortEnd}
                distance={1}
              />
            </div>
          ) : (
            <EmptyState type="cards" />
          ))}
      </div>
    )
  }
}

ManageListingsSelectedTab.propTypes = {
  active: PropTypes.bool.isRequired,
  ownerSingularName: PropTypes.string.isRequired,
  ownerPluralName: PropTypes.string.isRequired
}

function mapStateToProps(state, props) {
  const { ownerSingularName } = props
  const edit_item = state['edit_' + ownerSingularName]
  const selected_listings = edit_item.listings || []
  return {
    ownerId: edit_item.id,
    selected_listings: selected_listings
  }
}

export default connect(mapStateToProps)(ManageListingsSelectedTab)
