import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server'
import { camelize } from 'utils/Strings'

export class Marker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPopup: false
    }
    this.onClick = this.onClick.bind(this)
    if (props.onMove) {
      this.onMove = props.onMove.bind(this)
    }
  }

  componentDidMount() {
    //this.renderMarker();
  }
  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null)
    }
  }

  handleEvent(evtName) {
    return (e) => {
      const handlerName = `on${camelize(evtName)}`
      if (this.props[handlerName]) {
        this.props[handlerName](this.props, this.marker, e)
      }
    }
  }
  onClick(e) {
    if (
      ( this.props.type === 'recommendation' || this.props.type === 'listing' ) &&
      typeof this.props.onClick === 'function'
    ) {
      this.props.onClick(e)
    }
  }

  buildPopup = () => {
    if (this.state.showPopup && this.props.popup) {
      return this.props.popup
    } else {
      return null
    }
  }

  renderMarker() {
    let { map, google, position, id, size, fillColor, imageUrl, draggable } =
      this.props
    if (!google) {
      return null
    }

    let pos = position
    if (!(pos instanceof google.maps.LatLng)) {
      position = new google.maps.LatLng(pos.lat, pos.lng)
    }
    const height = size * 1.5
    const width = size
    const pinStyle = {
      fill: fillColor
    }
    const imgStyle = {}

    const imgFill = 'url(#image_' + id + ')'
    const popup = this.buildPopup()
    const markerMarkup = (
      <div>
        {popup}
        <div>
          <svg
            id={`svg_${id}`}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 50 75"
            enableBackground="new 0 0 50 75"
            height={height}
            width={width}
          >
            <g>
              <path
                id={`path_${id}`}
                style={pinStyle}
                d="M48.5,33.7L37,54.3L25.1,75L13.2,54.3L4.7,39.6c0,0-3-5.4-3.2-5.9C0.5,31,0,28.1,0,25C0,11.2,11.2,0,25,0c13.8,0,25,11.2,25,25C50,28.1,49.5,30.9,48.5,33.7z"
              />
              <defs>
                <pattern
                  id={`image_${id}`}
                  x={0}
                  y={0}
                  patternContentUnits="objectBoundingBox"
                  viewBox="0 0 1 1"
                  height="100%"
                  width="100%"
                >
                  <image
                    style={imgStyle}
                    x={0}
                    y={0}
                    width={1}
                    height={1}
                    xlinkHref={`${imageUrl}`}
                    src={imageUrl}
                    preserveAspectRatio="xMidYMid slice"
                  />
                </pattern>
              </defs>
              <circle id="top" cx={25} cy={25} r={23} fill={`${imgFill}`} />
            </g>
          </svg>
        </div>
      </div>
    )

    const svgDom = ReactDOMServer.renderToStaticMarkup(markerMarkup)
    const icon = {
      anchor: new google.maps.Point(width / 2, height),
      svg: svgDom,
      size: new google.maps.Size(height, width)
    }
    const pref = {
      map: map,
      position: position,
      icon: icon,
      onClick: this.onClick,
      onMove: this.onMove,
      draggable: draggable
    }

    // remove previous marker if it exists
    if (this.marker) {
      this.marker.setMap(null)
    }
    this.marker = new google.maps.SVGMarker(pref, google)
  }

  render() {
    this.renderMarker()
    return null
  }
}

Marker.defaultProps = {
  size: 75,
  fillColor: '#03a9f4',
  markerType: 'recommendation'
}

Marker.propTypes = {
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  position: PropTypes.object,
  map: PropTypes.object,
  size: PropTypes.number,
  fillColor: PropTypes.string,
  onClick: PropTypes.func,
  draggable: PropTypes.bool,
  onMove: PropTypes.func,
  markerType: PropTypes.string
}

export default Marker
