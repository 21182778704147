import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { injectIntl, intlShape } from 'react-intl'
import { OnAtMostPhablet, OnAtLeastTablet } from 'components/ScreenSize'
import BookAgainCard from './BookAgainCard'
import { guidebookShape } from 'constants/SharedPropTypes'

const propTypes = {
  guidebook: guidebookShape,
  listings: PropTypes.array,
  // helper for internationalization
  intl: intlShape.isRequired
}

const defaultProps = {}

class ListingsTab extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)
    this.tabRef = null
    this.state = {}
  }

  buildListings = (listings) => {
    const { guidebook } = this.props;
    return listings.map((listing) => {
      return (
        <BookAgainCard
          key={'listing_' + listing.id}
          guidebook={guidebook}
          listing={listing}
        />
      )
    })
  }

  columniseListings = (listings) => {
    const grid = {
      0: [],
      1: []
    }
    listings.forEach(function (rec, idx) {
      const pos = idx % 2
      let gridArr = grid[pos]
      if (!gridArr) {
        gridArr = []
        grid[pos] = gridArr
      }
      gridArr.push(rec)
    })
    return grid
  }

  renderCards = () => {
    const { listings } = this.props
    const listing_cards = this.buildListings(listings)
    const columns = this.columniseListings(listing_cards)
    return (
      <div>
        <OnAtMostPhablet>
          <div className="md-grid">
            <div className="md-cell md-cell--4 md-cell--4-tablet md-cell--4-phone">
              {listing_cards}
            </div>
          </div>
        </OnAtMostPhablet>
        <OnAtLeastTablet>
          <div className="md-grid">
            <div className="md-cell md-cell--6 md-cell--4-tablet">
              {columns[0]}
            </div>
            <div className="md-cell md-cell--6 md-cell--4-tablet">
              {columns[1]}
            </div>
          </div>
        </OnAtLeastTablet>
      </div>
    )
  }

  render() {
    const content = this.renderCards()
    return (
      <div
        className="md-cell md-cell--12"
        ref={(div) => {
          this.tabRef = div
        }}
      >
        {content}
      </div>
    )
  }
}

ListingsTab.propTypes = propTypes
ListingsTab.defaultProps = defaultProps

export default withCookies(connect(null)(injectIntl(ListingsTab)))
