import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Errors, actions } from 'react-redux-form'
import { withCookies } from 'react-cookie'
import {
  Avatar,
  Button,
  Card,
  CardTitle,
  List,
  ListItem,
  TextField
} from 'react-md'
import InputTextField from 'components/InputTextField'
import GooglePlaces from 'components/GooglePlaces'
import AddressMap from 'components/AddressMap'
import SelectForm from 'components/SelectForm'
import formatAddress from 'components/Address/FormatAddress'
import GoogleApiWrapper from 'components/Map/GoogleApiWrapper'
import { convertAndStoreImage } from 'redux/modules/filestack'
import { consolidatePlaceResults } from 'constants/GeoConstants'
import { directionsIcons } from 'constants/UIConstants'
import { openMap } from 'utils/Map'
import { preloadImages } from 'utils/Image'
import { ucFirst, idToTitle } from 'utils/Strings'
import { debounce } from 'utils/Debounce'

class AddressForm extends Component {
  constructor(props) {
    super(props)
    this.onSuggestionSelect = this.onSuggestionSelect.bind(this)
    this.markerMove = this.markerMove.bind(this)
    this.handleChangeLat = this.handleChangeLat.bind(this)
    this.handleChangeLng = this.handleChangeLng.bind(this)
    this.changeLatLng = debounce(this.changeLatLng.bind(this), 500)
    this.state = {
      showLatLng: this.getShowLatLngDefault(),
      current_lat: props.address.lat,
      current_lng: props.address.lng
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log('places props')
    if (nextProps.google && nextProps.google !== this.props.google) {
      const { google } = nextProps
      this.geocoder = new google.maps.Geocoder()
    }
  }

  // prevent renders where not necessary.
  shouldComponentUpdate(nextProps, nextState) {
    const { address, image } = this.props
    // console.log(address);
    const nextAddr = nextProps.address
    if (
      address.lat === nextAddr.lat &&
      address.lng === nextAddr.lng &&
      image === nextProps.image &&
      address.country_code === nextAddr.country_code &&
      address.locality === nextAddr.locality &&
      address.post_code === nextAddr.post_code &&
      address.state === nextAddr.state &&
      address.street === nextAddr.street &&
      address.street_number === nextAddr.street_number &&
      address.prefix === nextAddr.prefix &&
      address.google_place_url === nextAddr.google_place_url &&
      this.state.current_lat === nextState.current_lat &&
      this.state.current_lng === nextState.current_lng &&
      address.display_rule === nextAddr.display_rule &&
      this.state.showLatLng === nextState.showLatLng
    ) {
      return false
    }
    return true
  }

  // if the state of the lat or lng is changing, call our throttled model update function
  // this keeps it from trying to update on every single character the user types.
  componentWillUpdate(nextProps, nextState) {
    if (this.state.current_lat !== nextState.current_lat) {
      // if lat was changed, we need to grab lng from either state or props
      const lng = this.state.current_lng || this.props.lng
      this.changeLatLng(nextState.current_lat, lng)
    }
    if (this.state.current_lng !== nextState.current_lng) {
      const lat = this.state.current_lat || this.props.lat
      this.changeLatLng(lat, nextState.current_lng)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.google &&
      this.props.google_place_id &&
      !prevProps.google_place_id &&
      prevProps.google_place_id !== this.props.google_place_id
    ) {
      // get the images using this place id
      const maps = this.props.google.maps
      const place_request = { placeId: this.props.google_place_id }
      const detailService = new maps.places.PlacesService(
        document.createElement('div')
      )
      detailService.getDetails(place_request, (detailsResult, status) => {
        if (status === maps.places.PlacesServiceStatus.OK) {
          // update photos
          if (
            typeof detailsResult.photos !== 'undefined' &&
            detailsResult.photos.length > 0
          ) {
            this.props.onPlaceImagesUpdated(detailsResult.photos)
          }
          this.updateMissingDetails(detailsResult)
        }
      })
    }
  }

  // attempt to get address information from lat/lng
  reverseGeocode = (lat, lng) => {
    const { google } = this.props
    var latlng = { lat: lat, lng: lng }
    // console.log({'reverse_geocode': latlng});
    this.geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        // console.log(results);
        const result = consolidatePlaceResults(results)
        this.updateModelFromGeocode(result)
      }
    })
  }

  /** update this stuff only if it has no value currently */
  updateMissingDetails = (detailsResult) => {
    const { dispatch, editModel, parent } = this.props
    if (
      !parent.address.google_place_url &&
      typeof detailsResult.url !== 'undefined' &&
      detailsResult.url.length > 0
    ) {
      dispatch(
        actions.change(
          editModel + '.address.google_place_url',
          detailsResult.url
        )
      )
    }
    if (editModel === 'edit_recommendation') {
      // update phone number, website if currently empty in model (and this is a recommendation)
      if (
        !parent.website &&
        typeof detailsResult.website !== 'undefined' &&
        detailsResult.website.length > 0
      ) {
        dispatch(actions.change(editModel + '.website', detailsResult.website))
      }
      if (!parent.phone_number) {
        if (
          typeof detailsResult.international_phone_number !== 'undefined' &&
          detailsResult.international_phone_number.length > 0
        ) {
          dispatch(
            actions.change(
              editModel + '.phone_number',
              detailsResult.international_phone_number
            )
          )
        } else if (
          typeof detailsResult.formatted_phone_number !== 'undefined' &&
          detailsResult.formatted_phone_number.length > 0
        ) {
          dispatch(
            actions.change(
              editModel + '.phone_number',
              detailsResult.formatted_phone_number
            )
          )
        }
      }
    }
  }

  updateModelFromGeocode = (result) => {
    // console.log(result);

    const { dispatch, editModel } = this.props
    // only update things we found
    if (result.place_id) {
      dispatch(
        actions.change(editModel + '.address.google_place_id', result.place_id)
      )
    }
    if (result.formatted_address) {
      dispatch(
        actions.change(
          editModel + '.address.formatted_address',
          result.formatted_address
        )
      )
    }
    if (result.street_number) {
      dispatch(
        actions.change(
          editModel + '.address.street_number',
          result.street_number
        )
      )
    }
    if (result.route) {
      dispatch(actions.change(editModel + '.address.street', result.route))
    }
    if (
      result.locality ||
      result.neighborhood ||
      result.postal_town ||
      result.administrative_area_level_2
    ) {
      dispatch(
        actions.change(
          editModel + '.address.locality',
          result.locality ||
            result.neighborhood ||
            result.postal_town ||
            result.administrative_area_level_2
        )
      )
    }
    if (result.postal_town || result.administrative_area_level_1) {
      dispatch(
        actions.change(
          editModel + '.address.state',
          result.postal_town || result.administrative_area_level_1
        )
      )
    }
    if (result.postal_code) {
      dispatch(
        actions.change(editModel + '.address.post_code', result.postal_code)
      )
    }
    if (result.country) {
      dispatch(
        actions.change(editModel + '.address.country_code', result.country)
      )
    }
  }

  // read cookie to determine user's preference if any
  getShowLatLngDefault = () => {
    const { cookies } = this.props
    const cookieVal = cookies.get('hf_showLatLng')
    const showLatLng = cookieVal && cookieVal === 'true'
    return showLatLng
  }

  // just chnge the state right away
  // we'll throttle the actual updates to the model
  handleChangeLat = (value, e) => {
    this.setState({ current_lat: value })
  }
  handleChangeLng = (value, e) => {
    this.setState({ current_lng: value })
  }

  // show hide the lat/lng control and save preference in a cookie
  handleShowLatLng = () => {
    const { cookies } = this.props
    const setLatLngTo = this.state.showLatLng ? !this.state.showLatLng : true
    // remember preference with cookie
    const cookieOptions = {
      path: '/',
      expires: 0,
      sameSite: 'strict'
    }
    cookies.set('hf_showLatLng', setLatLngTo, cookieOptions)
    this.setState({ showLatLng: setLatLngTo })
  }

  // debounced function -  see constructor for debounce rate
  changeLatLng = (lat, lng) => {
    const { dispatch, editModel } = this.props
    const floatLat = parseFloat(lat)
    const floatLng = parseFloat(lng)
    if (floatLat && floatLng) {
      dispatch(actions.change(editModel + '.address.lat', floatLat))
      dispatch(actions.change(editModel + '.address.lng', floatLng))
      dispatch(actions.setValidity(editModel + '.address.lat', true))
      dispatch(actions.setValidity(editModel + '.address.lng', true))
      // remove place id since we're setting lat/lng directly
      dispatch(actions.change(editModel + '.address.google_place_id', null))
      // try a reverse geocoding too
      this.reverseGeocode(floatLat, floatLng)
    }
  }

  renderDisplayRule = (editModel) => {
    if (editModel === "edit_listing") return null;
    const address = this.props.address
    const { addressPrimary, addressSecondary } = formatAddress(address)
    const addressPreview = (
      <ListItem
        leftIcon={directionsIcons.map}
        primaryText={addressPrimary}
        secondaryText={addressSecondary}
        onClick={(event) => openMap(event, address, false)}
        threeLines
      />
    )

    let addressLinkDesc = "In the Arrival section of your guidebook, we construct a link to google maps for your guests. Here you can choose how that link is constructed."
    if (editModel === "edit_recommendation") {
      addressLinkDesc = "On the recommendation page, we construct a link to google maps for your guests. Here you can choose how that link is constructed."
    }
    return (
      <div className="md-cell md-cell--12 hf-headline-margin">
        <h2 className="md-headline">
          How would you like the address to be linked?
        </h2>
        <p>
          {addressLinkDesc}
        </p>

        <SelectForm
          className="md-cell md-cell--8 md-cell--5-tablet"
          label="Address Linking Rule"
          model={this.props.editModel + '.address.display_rule'}
          includeNone={false}
          itemLabel="name"
          itemValue="id"
        >
          {[
            { id: 'detect', name: 'automatic' },
            { id: 'address', name: 'use address' },
            { id: 'latlng', name: 'use lat/lon' }
          ]}
        </SelectForm>
        <Card className="md-cell md-cell--8 arrival arrival-card" raise={true}>
          <CardTitle
            avatar={
              <Avatar
                icon={directionsIcons.place}
                alt=""
                suffix="hfprimaryavatar"
              />
            }
            title={'Preview Address Link'}
          />
          <List>{addressPreview}</List>
        </Card>
      </div>
    )
  }

  renderLatLngFields = () => {
    // if state specifies to show the lat/lng fields...
    if (this.state.showLatLng === true) {
      const cellStyle = { marginTop: '18px' }
      return (
        <div className="md-cell md-cell--12" style={cellStyle}>
          <div className="md-grid md-grid--no-spacing">
            <div className="md-cell md-cell--6">
              <TextField
                id="address_lat"
                label="Latitude"
                lineDirection="center"
                placeholder="e.g.  35.163281"
                value={this.state.current_lat || this.props.lat}
                onChange={this.handleChangeLat}
              />
            </div>
            <div className="md-cell md-cell--6">
              <TextField
                id="address_lon"
                label="Longitude"
                lineDirection="center"
                placeholder="e.g.  -106.695955"
                value={this.state.current_lng || this.props.lng}
                onChange={this.handleChangeLng}
              />
            </div>
            <div className="md-cell md-cell--12">
              <Button flat primary onClick={this.handleShowLatLng}>
                hide lat/lng fields
              </Button>
            </div>
          </div>
        </div>
      )
    } else {
      // otherwise show a button to display the fields
      return (
        <div className="md-cell md-cell--12">
          <Button flat primary onClick={this.handleShowLatLng}>
            enter latitude / longitude directly
          </Button>
        </div>
      )
    }
  }

  setStreetViewImage = (suggest) => {
    const { dispatch, editModel } = this.props
    // construct the streetview URL
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_HOST
    const baseUrl = 'https://maps.googleapis.com/maps/api/streetview'
    const fullUrl = `${baseUrl}?size=600x450&fov=80&location=${suggest.lat},${suggest.lng}&key=${apiKey}`
    // convert to a filestack image and store it
    convertAndStoreImage(fullUrl).then((response) => {
      const streetViewImage = response.image_url ? response.image_url : fullUrl

      dispatch(actions.change(editModel + '.image', streetViewImage))
      preloadImages(streetViewImage, this.imageSizes)
    })
  }

  onSuggestionSelect = (suggest) => {
    const { dispatch, editModel, parent } = this.props

    // duplicate stuff abstracted to separate function
    this.updateModelFromGeocode(suggest)
    dispatch(actions.change(editModel + '.address.lat', suggest.lat))
    dispatch(actions.change(editModel + '.address.lng', suggest.lng))
    dispatch(
      actions.change(editModel + '.address.google_place_id', suggest.place_id)
    )
    if (suggest.google_place_url) {
      dispatch(
        actions.change(
          editModel + '.address.google_place_url',
          suggest.google_place_url
        )
      )
    }
    /// need to do something with recommendation details
    if (editModel === 'edit_recommendation') {
      if (suggest.name) {
        dispatch(actions.change(editModel + '.name', suggest.name))
      }
      if (suggest.website) {
        dispatch(actions.change(editModel + '.website', suggest.website))
      }
      if (suggest.phone_number) {
        dispatch(
          actions.change(editModel + '.phone_number', suggest.phone_number)
        )
      }
      // if we don't have a "why_recommended"
      if (suggest.types.length && suggest.locality) {
        const sample_why = `${ucFirst(idToTitle(suggest.types[0]))} in ${suggest.locality}`
        dispatch(actions.change(editModel + '.why_recommended', sample_why))
      }
    } else if (editModel === 'edit_guidebook' || editModel === 'edit_listing') {
      if (!parent.name) {
        dispatch(actions.change(editModel + '.name', suggest.name))
      }
      if (!parent.image) {
        this.setStreetViewImage(suggest)
      }
    }
    if (suggest.types && suggest.types.length) {
      this.props.onPlaceTypeUpdated(suggest.types)
    }
    if (suggest.photos && suggest.photos.length) {
      this.props.onPlaceImagesUpdated(suggest.photos)
    }
  }

  markerMove(pos) {
    const lat = pos.lat()
    const lng = pos.lng()
    this.changeLatLng(lat, lng)
  }

  errorMessages = {
    street: {
      required: 'Please provide a street address'
    },
    locality: {
      required: 'Please provide a city / suburb'
    },
    country_code: {
      required: 'Please provide a country'
    },
    lat: {
      required: 'Please provide a map location'
    }
  }

  render() {
    const { address, editModel, image, lat, lng, minimalist } = this.props
    const markerColor = !lat || !lng ? '#b1b1b1' : '#03A9F4'
    const markerImage = !lat || !lng ? '/safari-pinned-tab.svg' : image

    let searchPrompt = null
    let reviewPrompt = null
    let latlngPrompt = null
    let reviewForm = null
    let linkRule = null
    let placesForm = null

    const selectedAddressDisplay =
      address & address.formatted_address ? (
        <p>
          {address.formatted_address}
          <span title={'google_place_id: ' + address.google_place_id}>
            {' '}
            (lat={address.lat}, lng={address.lng})
          </span>
        </p>
      ) : null

    // we hide a lot of this form for the 'minimalist' implementation
    if (!minimalist) {
      if (editModel === 'edit_template') {
        searchPrompt = (
          <div>
            <h2 className="md-headline">
              Search for a city, town or suburb applicable to this template
            </h2>
            <p className="md-body-1">
              If you only operate in one location use that city/town/suburb. If
              you have more, you'll probably want different templates for each
              city/town/suburb.{' '}
            </p>
          </div>
        )
        reviewPrompt = (
          <h2 className="md-headline">
            Review the address below, you only need to complete the required
            fields (marked with *) for a template
          </h2>
        )
      } else {
        searchPrompt = (
          <div>
            <h2 className="md-headline">Search by address or business name</h2>
            <p className="md-body-1">
              Based on your selection, we'll populate as much as we can in the
              fields below about this location
            </p>
          </div>
        )
        reviewPrompt = (
          <h2 className="md-headline">
            Review the address below, do you need to add a prefix or apartment
            number?
          </h2>
        )
      }
      latlngPrompt = this.renderLatLngFields()
      placesForm = (
        <Card className="hf-wider-selection-control-container">
          <div className="md-grid">
            <div className="md-cell md-cell--12">
              <GooglePlaces
                onSuggestSelect={this.onSuggestionSelect}
                prompt={this.props.prompt}
              />
              {selectedAddressDisplay}
              {latlngPrompt}
            </div>
          </div>
        </Card>
      )
      reviewForm = (
        <div className="md-cell md-cell--12 hf-headline-margin">
          {reviewPrompt}
          <Card className="hf-wider-selection-control-container">
            <div className="md-grid md-grid--no-spacing">
              <div className="md-cell md-cell--6 md-cell--12-tablet">
                <div className="md-cell md-cell--12">
                  <InputTextField
                    model=".address.prefix"
                    id="address_prefix"
                    label="Prefix / apartment no."
                  />
                </div>
                <div className="md-cell md-cell--12">
                  <InputTextField
                    model=".address.street_number"
                    id="address_street_number"
                    label="Street no."
                  />
                </div>
                <div className="md-cell md-cell--12">
                  <InputTextField
                    model=".address.street"
                    id="address_street"
                    label="Street name"
                  />
                  <Errors
                    model=".address.street"
                    messages={this.errorMessages.street}
                    className="md-text-field-message hf-error"
                    show={(field) => field.touched && !field.focus}
                  />
                </div>
                <div className="md-cell md-cell--12">
                  <InputTextField
                    model=".address.locality"
                    id="address_locality"
                    label="City / suburb *"
                  />
                  <Errors
                    model=".address.locality"
                    messages={this.errorMessages.locality}
                    className="md-text-field-message hf-error"
                    show={(field) => field.touched && !field.focus}
                  />
                </div>
                <div className="md-cell md-cell--12">
                  <InputTextField
                    model=".address.state"
                    id="address_state"
                    label="State / Province"
                  />
                </div>
                <div className="md-cell md-cell--12">
                  <InputTextField
                    model=".address.country_code"
                    id="address_country_code"
                    label="Country *"
                  />
                  <Errors
                    model=".address.country_code"
                    messages={this.errorMessages.country_code}
                    className="md-text-field-message hf-error"
                    show={(field) => field.touched && !field.focus}
                  />
                </div>
                <div className="md-cell md-cell--12">
                  <InputTextField
                    model=".address.post_code"
                    id="address_post_code"
                    label="Postal Code"
                  />
                </div>
              </div>
              <div className="md-cell md-cell--6 md-cell--12-tablet">
                <AddressMap
                  address={address}
                  image={markerImage}
                  markerColor={markerColor}
                  className="map-container md-toolbar-relative hf-hideOnLessThanPhablet"
                  fitType="bounds"
                  onMarkerMove={this.markerMove}
                />
                <Errors
                  model=".address.lat"
                  messages={this.errorMessages.lat}
                  className="md-text-field-message hf-error"
                  show={(field) => field.touched && !field.focus}
                />
              </div>
            </div>
          </Card>
        </div>
      )
      linkRule = this.renderDisplayRule(editModel)
    } else {
      placesForm = (
        <div>
          <GooglePlaces
            onSuggestSelect={this.onSuggestionSelect}
            prompt={this.props.prompt}
          />
          {selectedAddressDisplay}
          {latlngPrompt}
        </div>
      )
    }

    return (
      <div className="md-grid">
        <div className="md-cell md-cell--12">
          {searchPrompt}
          {placesForm}
        </div>
        {reviewForm}
        {linkRule}
      </div>
    )
  }
}

AddressForm.propTypes = {
  prompt: PropTypes.string,
  onPlaceImagesUpdated: PropTypes.func,
  onPlaceTypeUpdated: PropTypes.func,
  minimalist: PropTypes.bool
}

AddressForm.defaultProps = {
  prompt: null,
  onPlaceImagesUpdated: () => {},
  onPlaceTypeUpdated: () => {},
  minimalist: false
}

AddressForm.contextTypes = {
  model: PropTypes.any // could be a string or tracking function, choose what works in your use case
}

function mapStateToProps(state, props) {
  const { editModel } = props
  const parent = state[editModel]
  const address = state[editModel].address
  const lat = address ? address.lat : null
  const lng = address ? address.lng : null
  const google_place_id =
    address && address.google_place_id ? address.google_place_id : null
  return {
    lat,
    lng,
    google_place_id,
    parent
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_HOST
})(withCookies(connect(mapStateToProps)(AddressForm)))
