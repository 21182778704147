import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { Card, CardText, CardTitle, Media, MediaOverlay } from 'react-md'
import { connect } from 'react-redux'

import ImageFallback from 'components/ImageFallback'
import {
  atLeastDesktop,
  atLeastTablet,
  atMostPhablet
} from 'components/ScreenSize'
import Translator from 'components/Translator'
import AsHtml from 'components/Translator/AsHtml'
import { guidebookShape, listingShape } from 'constants/SharedPropTypes'
import { shadeBlend } from 'utils/Color'
import { trackCardOpened } from 'utils/GTM';
import { resizeImage } from 'utils/Image'

const propTypes = {
  listing: listingShape,
  guidebook: guidebookShape,
  // helper for internationalization
  intl: intlShape.isRequired
}

const defaultProps = {}

class ListingCard extends Component {
  restoreColor = null
  restoreSizeAndPos = {
    height: null,
    width: null,
    left: null,
    top: null,
    zIndex: null
  }

  resizeListingImage = (image) => {
    const fallbackImage = image || '/add-place-img.svg'
    let srcImage = image || '/add-place-img.svg'

    if (image) {
      // for desktop and up, grab images 350 wide
      if (atLeastDesktop) {
        srcImage = resizeImage(image, 348, 261, true)
        // for tablet and up, grab images 480 wide
      } else if (atLeastTablet) {
        srcImage = resizeImage(image, 480, 360, true)
      } else if (atMostPhablet) {
        srcImage = resizeImage(image, 748, 561, true)
      }
    }
    // otherwise
    return { srcImage: srcImage, fallbackImage: fallbackImage }
  }

  openListing = (listing) => {
    const guidebook_id = this.props.guidebook.id || 0
    const host_user_id = this.props.guidebook.host_user_id || 0
    const tab_name = 'book_again'
    const card_name = this.props.listing.name || null
    // send card_opened event to GTM/GA4
    trackCardOpened(guidebook_id, host_user_id, tab_name, card_name, null);
    window.open(
      listing.booking_link,
      '_blank'
    );
  }

  hoverListing = (listingId, isHovering) => {
    const pathEle = document.getElementById(`path_listing_${listingId}`)
    const svgEle = document.getElementById(`svg_listing_${listingId}`)
    if (svgEle) {
      const containerDiv = svgEle.parentNode.parentNode.parentNode
      // console.log(containerDiv);
      if (isHovering) {
        const highlightHeight = 99
        const highlightWidth = 66
        // save original color and styles so we can restore them later
        this.restoreColor = pathEle.style.fill
        this.restoreSizeAndPos.height = svgEle.height.baseVal.value
        this.restoreSizeAndPos.width = svgEle.width.baseVal.value
        this.restoreSizeAndPos.top = parseFloat(containerDiv.style.top)
        this.restoreSizeAndPos.left = parseFloat(containerDiv.style.left)
        this.restoreSizeAndPos.zIndex = containerDiv.parentNode.style.zIndex
        // record change in height and width so we can adjust position
        const dTop = highlightHeight - this.restoreSizeAndPos.height
        const dLeft = (highlightWidth - this.restoreSizeAndPos.width) / 2
        // now actually change the height and width
        svgEle.setAttribute('height', `${highlightHeight}`)
        svgEle.setAttribute('width', `${highlightWidth}`)
        // change the position to reflect the size change and keep the point pointed properly
        containerDiv.style.top = `${this.restoreSizeAndPos.top - dTop}px`
        containerDiv.style.left = `${this.restoreSizeAndPos.left - dLeft}px`
        containerDiv.style.zIndex = `${this.restoreSizeAndPos.zIndex + 1}`
        // change the color
        pathEle.style.fill = shadeBlend(0.33, this.restoreColor)
      } else if (this.restoreColor) {
        // restore original height
        svgEle.setAttribute('height', `${this.restoreSizeAndPos.height}`)
        svgEle.setAttribute('width', `${this.restoreSizeAndPos.width}`)
        // restore original pos
        containerDiv.style.top = `${this.restoreSizeAndPos.top}px`
        containerDiv.style.left = `${this.restoreSizeAndPos.left}px`
        containerDiv.style.zIndex = `${this.restoreSizeAndPos.zIndex}`
        // restore original color
        pathEle.style.fill = this.restoreColor
        // clear stored variables
        this.restoreColor = null
        this.restoreSizeAndPos = {
          height: null,
          width: null,
          left: null,
          top: null,
          zIndex: null
        }
      }
    }
  }

  render() {
    const { listing } = this.props
    const { srcImage, fallbackImage } = this.resizeListingImage(
      listing.image
    )
    const aspectRatio = '4-3'
    const listingNameTextString = (
      <Translator
        text={listing.name}
        translations={listing.name_txn}
      />
    )
    const subTitle = '';
    const listingDescTextString = (
      <AsHtml
        text={listing.description}
        translations={listing.description_txn}
      />
    )

    return (
      <Card
        key={listing.id}
        raise={true}
        className="hf-clickable"
        onMouseEnter={() => this.hoverListing(listing.id, true)}
        onMouseLeave={() => this.hoverListing(listing.id, false)}
        onClick={() => {
          this.openListing(listing)
        }}
      >
        <Media aspectRatio={aspectRatio}>
          <ImageFallback
            src={srcImage}
            fallback={fallbackImage}
            role="presentation"
          />
          <MediaOverlay>
            <CardTitle
              title={listingNameTextString}
              subtitle={subTitle}
              className="hf-rec-overlay-title"
            ></CardTitle>
          </MediaOverlay>
        </Media>
        <CardText dir="auto">
          <p className="host-quote">{listingDescTextString}</p>
        </CardText>
      </Card>
    )
  }
}

ListingCard.propTypes = propTypes
ListingCard.defaultProps = defaultProps

export default connect(null)(injectIntl(ListingCard))
