import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Avatar,
  Button,
  Card,
  CardTitle,
  FontIcon,
  LinearProgress
} from 'react-md'
import ManageMarketplace from './ManageMarketplace'
import currentUser from 'utils/CurrentUser'

const propTypes = {
  loaded: PropTypes.bool.isRequired,
  services: PropTypes.array.isRequired,
  servicesError: PropTypes.bool,
  allServices: PropTypes.array.isRequired,
  allServicesError: PropTypes.bool,
  onSelectionChanged: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onUpgradeClick: PropTypes.func.isRequired
}

class MarketplaceSection extends Component {
  render() {
    const { status } = this.props
    const user = currentUser()
    const paddingStyle = { paddingBottom: '24px' }
    const bgStyle = { background: '#cccccc' }
    const progressStyle = { background: '#ffc107' }

    let marketplace_content = null

    const renderLoading = (
      <div className="md-grid md-grid--no-spacing">
        <div
          className="md-cell md-cell--12 md-text-center"
          style={paddingStyle}
        >
          <LinearProgress
            id="status_check"
            style={bgStyle}
            progressStyle={progressStyle}
          />
          <div>Loading Marketplace...</div>
        </div>
      </div>
    )

    if (user.canUpsell) {
      if (status.isLoading || !this.props.loaded) {
        marketplace_content = renderLoading
      } else if (
        status.hasErrored ||
        (status.data.connected &&
          (this.props.servicesError || this.props.allServicesError))
      ) {
        // console.log({
        //   hasErrored: status.hasErrored,
        //   connected: status.data,
        //   servicesError: this.props.servicesError,
        //   allServicesError: this.props.allServicesError
        // })
        marketplace_content = (
          <div
            className="md-cell md-cell--12 md-text-center"
            style={paddingStyle}
          >
            <div>
              <FontIcon className="error" key="error">
                error_outline
              </FontIcon>
            </div>
            <div>
              Unfortunately, something went wrong fetching marketplace data
              <br />
              Please try reloading the page, or trying again in a few minutes.
              <br />
              <br />
              If the issue persists, please contact{' '}
              <a href="mailto:support@hostfully.com">support@hostfully.com</a>
            </div>
          </div>
        )
      } else if (this.props.loaded && status.data.connected) {
        marketplace_content = (
          <ManageMarketplace
            singularName="guidebook"
            pluralName="guidebooks"
            selectedServices={this.props.services}
            allServices={this.props.allServices}
            onSelectionChanged={this.props.onSelectionChanged}
            onSort={this.props.onSort}
            {...this.props}
          />
        )
      } else {
        marketplace_content = (
          <div
            className="md-cell md-cell--12 md-text-center"
            style={paddingStyle}
          >
            <div>
              <FontIcon className="error" key="error">
                link_off
              </FontIcon>
            </div>
            <div>
              It appears that your marketplace is not yet set up.
              <br />
              Please click <a href="/host/marketplace">Marketplace</a> to get
              started.
              <br />
              (save your guidebook first if you've made changes)
            </div>
          </div>
        )
      }
    } else {
      marketplace_content = (
        <div className="md-grid md-grid--no-spacing">
          <div className="md-cell md-cell--12 md-text-center">
            <Button
              onClick={this.props.onUpgradeClick}
              iconChildren="account_circle"
              flat
              primary
            >
              Activate
            </Button>
          </div>
          <div className="md-cell md-cell--12 md-text-center">
            <p>Upgrade to a paid plan to add a Marketplace to your guidebook</p>
          </div>
        </div>
      )
    }

    return (
      <Card className="hf-wider-selection-control-container">
        <CardTitle
          avatar={
            <Avatar
              icon={<FontIcon>shopping_cart</FontIcon>}
              role="presentation"
              suffix="hfteal"
            />
          }
          title="Marketplace cards"
        />
        {marketplace_content}
      </Card>
    )
  }
}

MarketplaceSection.propTypes = propTypes

function mapStateToProps(state, props) {
  const orbiStatus = state['orbiStatusState']

  return {
    status: orbiStatus
  }
}

export default connect(mapStateToProps)(MarketplaceSection)
