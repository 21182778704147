import React from 'react'
import Marker from 'components/Map/Marker'
import { resizeImage } from 'utils/Image'
import { customColor } from 'utils/Color'

export default function ListingsMarkers(
  listings,
  size,
  showPopup,
  fillColor,
  clickHandler,
  theme
) {
  return (
    // filter the ones that have no address
    listings
      .filter((listing) => {
        return listing.address !== null
      })
      .map((listing) => {
        const rpos = {
          lat: listing.address.lat,
          lng: listing.address.lng
        }

        const clickHandlerFn = clickHandler
          ? (e) => {
              clickHandler(listing, e)
            }
          : null

        let markerColor = fillColor || '#573C0A'

        markerColor = customColor(
          "book_again",
          theme,
          markerColor
        )

        const imgSize = parseInt(size, 10) * 2
        const image = listing.image
          ? resizeImage(listing.image, imgSize, imgSize, true)
          : '/add-place-img.svg'

        const marker = (
          <Marker
            key={'listingmarker-' + listing.id}
            id={'listing_' + listing.id}
            type="listing"
            name={listing.name}
            imageUrl={image}
            clickable={true}
            draggable={false}
            fillColor={markerColor}
            onClick={clickHandlerFn}
            height={size}
            width={size}
            size={size}
            position={rpos}
            popup={null}
          />
        )
        return marker
      })
  )
}
