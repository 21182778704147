import React from 'react'
import { defineMessages } from 'react-intl'
import FontIcon from 'react-md/lib/FontIcons'

// Common Icons
const placeIcon = <FontIcon>place</FontIcon>
const timeIcon = <FontIcon>access_time</FontIcon>
const mapIcon = <FontIcon>map</FontIcon>

// Possible values for essential label assigned to the message id.
export const essentialsLabel = {
  0: 'our_place',
  1: 'my_place',
  2: 'the_listing',
  3: 'about',
  4: 'home',
  5: 'the_hotel',
  6: 'on_board',
  7: 'our_event',
  8: 'details'
}

export const genericMessages = defineMessages({
  read_more: {
    id: 'gb.readMore',
    defaultMessage: 'Read More'
  }
})

//Actual message text for essentials labels
export const localeMessages = defineMessages({
  'ar-AE': {
    id: 'gb.ar-AE',
    defaultMessage: 'Arabic'
  },
  'da-DK': {
    id: 'gb.da-DK',
    defaultMessage: 'Danish'
  },
  'de-DE': {
    id: 'gb.de-DE',
    defaultMessage: 'German'
  },
  'el-GR': {
    id: 'gb.el-GR',
    defaultMessage: 'Greek'
  },
  'en-US': {
    id: 'gb.en-US',
    defaultMessage: 'English'
  },
  'es-ES': {
    id: 'gb.es-ES',
    defaultMessage: 'Spanish'
  },
  'fr-FR': {
    id: 'gb.fr-FR',
    defaultMessage: 'French'
  },
  'he-IL': {
    id: 'gb.he-IL',
    defaultMessage: 'Hebrew'
  },
  'it-IT': {
    id: 'gb.it-IT',
    defaultMessage: 'Italian'
  },
  'ja-JP': {
    id: 'gb.ja-JP',
    defaultMessage: 'Japanese'
  },
  'nl-NL': {
    id: 'gb.nl-NL',
    defaultMessage: 'Dutch'
  },
  'pl-PL': {
    id: 'gb.pl-PL',
    defaultMessage: 'Polish'
  },
  'pt-BR': {
    id: 'gb.pt-BR',
    defaultMessage: 'Portuguese (BR)'
  },
  'pt-PT': {
    id: 'gb.pt-PT',
    defaultMessage: 'Portuguese (PT)'
  },
  'ru-RU': {
    id: 'gb.ru-RU',
    defaultMessage: 'Russian'
  },
  'vi-VN': {
    id: 'gb.vi-VN',
    defaultMessage: 'Vietnamese'
  },
  'zh-TW': {
    id: 'gb.zh-TW',
    defaultMessage: 'Chinese'
  }
})

export const autoTranslateCodes = {
  'en-US': 'en',
  'da-DK': 'da',
  'de-DE': 'de',
  'el-GR': 'el',
  'he-IL': 'he',
  'it-IT': 'it',
  'ja-JP': 'ja',
  'fr-FR': 'fr',
  'es-ES': 'es',
  'nl-NL': 'nl',
  'pl-PL': 'pl',
  'pt-BR': 'pt',
  'pt-PT': 'pt',
  'ru-RU': 'ru',
  'vi-VN': 'vi',
  'zh-TW': 'zh-TW',
  'ar-AE': 'ar'
}

export const nativeLanguageNames = {
  'en-US': 'English',
  'da-DK': 'Dansk',
  'de-DE': 'Deutsch',
  'el-GR': 'Ελληνικά',
  'he-IL': 'עברית',
  'ar-AE': 'العربية',
  'it-IT': 'Italiano',
  'ja-JP': '日本語',
  'fr-FR': 'Français',
  'es-ES': 'Español',
  'nl-NL': 'Nederlands',
  'pl-PL': 'Polski',
  'pt-BR': 'Português (BR)',
  'pt-PT': 'Português (PT)',
  'ru-RU': 'Русский',
  'vi-VN': 'Tiếng Việt',
  'zh-TW': '中文'
}

export const allLocales = [
  'es-ES',
  'fr-FR',
  'ja-JP',
  'da-DK',
  'de-DE',
  'nl-NL',
  'it-IT',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ru-RU',
  'vi-VN',
  'zh-TW',
  'el-GR',
  'he-IL',
  'ar-AE'
]

export const markerColors = {
  arrival: '#F44336' /* $md-red-500; */,
  house_manual: '#FF5722' /* $md-deep-orange-500; */,
  departure: '#FF9800' /* $md-orange-500; */,
  marketplace: '#FFC107',
  food: '#FFC107' /* $md-amber-500; */,
  activities: '#4CAF50' /* $md-green-500; */,
  bars: '#009688' /* $md-teal-500; */,
  attractions: '#03A9F4' /* $md-light-blue-500; */,
  shopping: '#3F51B3' /* $md-indigo-500; */,
  other: '#9C27B0' /* $md-purple-500; */,
  all: '#E91E63' /* $md-ping-500; */
}

export const categoryIconSvg = {
  arrival: (
    <g>
      <path fill="none" d="M149.7,50l0.3,99.4l-97.8,0.3l-0.3-98.6L149.7,50z" />
      <path
        fill="#ffffff"
        d="M109.2,103.2c10-3.6,17-13.6,16.1-25.1c-0.9-12.1-10.7-21.9-22.7-22.7c-14.3-1-26.2,10.5-26.2,24.7
    c0,10.7,6.9,19.8,16.4,23.2l0.1,17.1l-16.3,0.1l0.1,17.2l16.3-0.1l0,8.2l16.3-0.1L109.2,103.2z M92.6,79.5c0-4.5,3.6-8.3,8.1-8.3
    c4.5,0,8.2,3.7,8.2,8.2c0,4.5-3.6,8.3-8.1,8.3C96.3,87.8,92.6,84.1,92.6,79.5z"
      />
    </g>
  ),
  house_manual: (
    <g>
      <path
        fill="#ffffff"
        d="M144.1,58c-5.4-1.7-11.4-2.4-17.2-2.4c-9.6,0-19.9,1.9-27,7.3c-7.1-5.4-17.4-7.3-27-7.3s-19.9,1.9-27,7.3
            l0,71.1c0,1.1,0.7,2.1,1.7,2.5c1.1,0.4,1.3-0.1,2-0.1c6.6-3.2,16.2-5.4,23.3-5.4c9.6,0,19.9,1.9,27,7.3c6.6-4.1,18.6-7.3,27-7.3
            c8.1,0,16.4,1.5,23.3,5.1c0.5,0.2,0.7,0.2,1.2,0.2c1.3,0,2.5-1.1,2.5-2.4l0-71C151,60.7,147.8,59.2,144.1,58z M143.8,123.4
            c-5.3-1.7-11.2-2.4-17-2.4c-8.3,0-20.2,3.1-26.7,7.2V72.8c6.6-4.1,18.5-7.2,26.7-7.2c5.8,0,11.7,0.7,17,2.4V123.4z"
      />
      <rect
        fill="none"
        id="SVGID_1_"
        x="41"
        y="33.9"
        width="117"
        height="116.1"
      />
    </g>
  ),
  departure: (
    <g>
      <path fill="none" d="M47.5,47.5h105v105h-105V47.5z" />
      <path
        fill="#ffffff"
        d="M130.8,60.5h-18.4c-1.8-5.1-6.7-8.8-12.4-8.8s-10.5,3.7-12.4,8.8H69.2c-4.8,0-8.8,3.9-8.8,8.8v61.5
			c0,4.8,3.9,8.8,8.8,8.8h61.5c4.8,0,8.8-3.9,8.8-8.8V69.2C139.5,64.4,135.6,60.5,130.8,60.5z M99.5,60.1c2.4,0,4.4,2,4.4,4.4
			s-2,4.4-4.4,4.4s-4.4-2-4.4-4.4S97.1,60.1,99.5,60.1z M108.5,121.5h-31v-9h31V121.5z M121.5,103.5h-44v-9h44V103.5z M121.5,86.5
			h-44v-9h44V86.5z"
      />
    </g>
  ),
  food: (
    <g>
      <path
        fill="#ffffff"
        d="M92.4,87.6h-8V58.8h-8.8v28.8h-8V58.8h-8.8v29.1c0,8.8,7.1,16,16,16.5V142h9.6v-37.6c8.9-0.5,16-7.7,16-16.5
			V58.8h-8V87.6z M113.2,75.3v33.1h10.4V142H134V58.6C122.5,58.6,113.2,67.9,113.2,75.3z"
      />
      <path fill="none" d="M46,50h100v100H46V50z" />
    </g>
  ),
  activities: (
    <g>
      <path fill="none" d="M36,41.3h128v117.4H36V41.3z" />
      <path
        fill="#ffffff"
        d="M142.6,99.5c0-5.4,4.7-9.8,10.5-9.8V70c0-5.4-4.8-9.8-10.7-9.8H57.6c0,0-10.6,5.1-10.8,10.5v19.5
			c6.1,0,10.8,4.4,10.8,9.8s-9.7,9.8-9.7,9.8V129c0,5.4,4.8,9.8,10.7,9.8h83.9c5.9,0,10.7-4.4,10.7-9.8v-19.7
			C147.4,109.3,142.6,104.9,142.6,99.5z M119.3,123.4l-19.1-11.2l-19.1,11.2l5.8-20.1L69.4,90.2L92,88.9l8.3-19.3l8.2,19.3l22.6,1.2
			l-17.5,13.1L119.3,123.4z"
      />
    </g>
  ),
  bars: (
    <g>
      <path
        fill="#ffffff"
        d="M138,70.4V62H62v8.5l34.3,38.1v21.2H74v8.3h51v-8.3h-21.3v-21.2L138,70.4z M80.9,78.7l-7.4-8.3h53l-7.4,8.3
			H80.9z"
      />
      <path fill="none" d="M49,49h102v102H49V49z" />
    </g>
  ),
  attractions: (
    <g>
      <circle fill="#ffffff" cx="99.5" cy="99.4" r="16.7" />
      <path
        fill="#ffffff"
        d="M83.7,47.5l-9.5,11.1H57.9c-5.7,0-10.4,4.7-10.4,10.4v62.1c0,5.7,4.7,10.4,10.4,10.4H141
			c5.7,0,10.4-4.7,10.4-10.4V68.9c0-5.7-4.7-10.4-10.4-10.4h-16.5L115,47.5H83.7z M99.5,125.5c-14.4,0-26-11.7-26-26s11.7-26,26-26
			s26,11.7,26,26S113.8,125.5,99.5,125.5z"
      />
      <path fill="none" d="M37.5,37.5h125v125h-125V37.5z" />
    </g>
  ),
  shopping: (
    <g>
      <path fill="none" d="M40,36h120v120H40V36z" />
      <path
        fill="#ffffff"
        d="M126,81l-21.9-32.8c-0.9-1.4-2.5-2.1-4.1-2.1s-3.2,0.7-4.2,2.2L73.9,81H49.9c-2.7,0-4.9,2.1-5,4.8
			c0,0.5,0,1,0.2,1.5l12.7,46.4c1.2,4.2,5,7.3,9.6,7.3h65c4.6,0,8.5-3.1,9.7-7.3l12.7-46.4l0.1-1.4c0-2.8-2.2-5-5-5H126z M84.9,81
			l15-22l15,22H84.9z M99.9,121.3c-5.5,0-10-4.5-10-10c0-5.5,4.5-10,10-10c5.5,0,10,4.5,10,10C109.9,116.8,105.4,121.3,99.9,121.3z"
      />
    </g>
  ),
  other: (
    <g>
      <path fill="none" d="M51,46h110v110H51V46z" />
      <path
        fill="#ffffff"
        d="M148.9,98.9l-41.2-41.2c-1.6-1.6-3.9-2.7-6.5-2.7H69.2c-5,0-9.2,4.1-9.2,9.2v32.1c0,2.5,1,4.8,2.7,6.5
			l41.2,41.2c1.6,1.6,3.9,2.7,6.5,2.7c2.5,0,4.8-1,6.5-2.7l32.1-32.1c1.7-1.6,2.7-3.9,2.7-6.5C151.6,102.9,150.5,100.5,148.9,98.9z
			 M76.1,78.2c-3.8,0-6.9-3.1-6.9-6.9c0-3.8,3.1-6.9,6.9-6.9c3.8,0,6.9,3.1,6.9,6.9C83,75.1,79.9,78.2,76.1,78.2z M130,116
			l-19.6,19.6L90.9,116c-2.1-2.1-3.3-4.9-3.3-8.1c0-6.3,5.1-11.4,11.4-11.4c3.2,0,6,1.3,8.1,3.4l3.3,3.3l3.3-3.3
			c2.1-2.1,4.9-3.3,8.1-3.3c6.3,0,11.4,5.1,11.4,11.4C133.3,111.1,132.1,114,130,116z"
      />
    </g>
  ),
  all: (
    <g>
      <path
        fill="#ffffff"
        d="M49,82h21V61H49V82z M49,109h21V88H49V109z M76,109h21V88H76V109z M103,109h21V88h-21V109z M76,82h21V61H76
			V82z M103,61v21h21V61H103z M129,109h21V88h-21V109z M49,135h21v-21H49V135z M76,135h21v-21H76V135z M103,135h21v-21h-21V135z
			 M129,135h21v-21h-21V135z M129,61v21h21V61H129z"
      />
      <path fill="none" d="M33,34h128v128H33V34z" />
    </g>
  )
}

export const labelMessages = defineMessages({
  our_place: {
    id: 'gb.our_place',
    defaultMessage: 'Our place'
  },
  my_place: {
    id: 'gb.my_place',
    defaultMessage: 'My place'
  },
  the_listing: {
    id: 'gb.the_listing',
    defaultMessage: 'The listing'
  },
  about: {
    id: 'gb.about',
    defaultMessage: 'About'
  },
  home: {
    id: 'gb.home',
    defaultMessage: 'Home'
  },
  the_hotel: {
    id: 'gb.the_hotel',
    defaultMessage: 'The hotel'
  },
  on_board: {
    id: 'gb.on_board',
    defaultMessage: 'On board'
  },
  our_event: {
    id: 'gb.our_event',
    defaultMessage: 'Our event'
  },
  details: {
    id: 'gb.details',
    defaultMessage: 'Details'
  },
  arrival: {
    id: 'gb.arrival',
    defaultMessage: 'Arrival'
  },
  house_manual: {
    id: 'gb.houseManual',
    defaultMessage: 'House manual'
  },
  departure: {
    id: 'gb.departure',
    defaultMessage: 'Departure'
  },
  ai: {
    id: 'gb.ai',
    defaultMessage: 'AI Planner'
  },
  marketplace: {
    id: 'gb.marketplace',
    defaultMessage: 'Marketplace'
  },
  book_again: {
    id: 'gb.book_again',
    defaultMessage: 'Book Again'
  },
  emergency: {
    id: 'gb.emergency',
    defaultMessage: 'Emergency'
  },
  map_view: {
    id: 'gb.map_view',
    defaultMessage: 'Map view'
  },
  list_view: {
    id: 'gb.list_view',
    defaultMessage: 'List view'
  },
  nav_map_view: {
    id: 'gb.nav_map_view',
    defaultMessage: 'Map'
  },
  nav_list_view: {
    id: 'gb.nav_list_view',
    defaultMessage: 'List'
  },
  nav_settings: {
    id: 'gb.nav_settings',
    defaultMessage: 'Settings'
  },
  contact: {
    id: 'gb.contact',
    defaultMessage: 'Contact'
  },
  address: {
    id: 'gb.address',
    defaultMessage: 'Address'
  },
  recTitle: {
    id: 'gb.recTitle',
    defaultMessage: 'Hostfully recommended'
  },
  print: {
    id: 'gb.print',
    defaultMessage: 'Print'
  },
  aboutHostfully: {
    id: 'footer.About',
    defaultMessage: 'About Hostfully'
  },
  poweredBy: {
    id: 'footer.Powered',
    defaultMessage: 'Powered by'
  },
  makeOwn: {
    id: 'footer.makeOwn',
    defaultMessage: 'Like this guidebook? Make your own!'
  }
})

// These are the current tabs on the Essentials page
export const essentialsTabIndexes = ['arrival', 'house_manual', 'departure']

export const allNavIndexes = [
  'guidebooks',
  'marketplace',
  'hostintros',
  'recommendations',
  'informations',
  'checkins',
  'checkouts',
  'wifis',
  'directions',
  'parkings',
  'themes',
  'categories',
  'templates',
  'listings',
  'tokens',
  'domains'
]

// These are the nav items for Hosts
export const hostNavIndexes = [
  'guidebooks',
  'marketplace',
  'hostintros',
  'recommendations',
  'informations',
  'checkins',
  'checkouts',
  'wifis',
  'directions',
  'parkings',
  'themes',
  'categories',
  'templates',
  'listings',
  'tokens',
  'domains'
]
export const proNavIndexes = []
export const enterpriseNavIndexes = []

// wether to show the page, or show an upsell advert
export const hostPageIndexes = [
  'guidebooks',
  'informations',
  'recommendations',
  'hostintros',
  'directions',
  'parkings',
  'checkins',
  'wifis',
  'checkouts',
  'user_settings'
]
export const proPageIndexes = ['themes', 'categories', 'listings']
export const enterprisePageIndexes = ['templates']

export const basicCRUDIndexes = [
  'guidebooks',
  'hostintros',
  'directions',
  'parkings',
  'checkins',
  'wifis',
  'checkouts',
  'informations',
  'recommendations'
]
export const proCRUDIndexes = ['themes', 'categories', 'listings']
export const enterpriseCRUDIndexes = ['templates']

export const filterNavIndexes = (pluralName, user) => {
  // MN - let's show the big upsell page indexes to everyone
  // for free users we're still going to hide the CRUD pages for most things though
  if (hostNavIndexes.indexOf(pluralName) !== -1) {
    return true
  } else if (
    (user.isEnterprise || user.isPro) &&
    proNavIndexes.indexOf(pluralName) !== -1
  ) {
    return true
  } else if (
    user.isEnterprise &&
    enterpriseNavIndexes.indexOf(pluralName) !== -1
  ) {
    return true
  } else if (user.canUseDomain && pluralName === 'domains') {
    return true
  } else if (user.canUpsell && pluralName === 'marketplace') {
    return true
  } else if (user.canTheme && pluralName === 'themes') {
    return true
  } else if (user.canCreateCategory && pluralName === 'categories') {
    return true
  } else if (user.canUseSecurity && pluralName === 'tokens') {
    return true
  }
  return false
}

// we need to allow basic CRUD access to many more things when creating/editing through the guidebook
export const filterCRUDAccess = (pluralName, user) => {
  if (basicCRUDIndexes.indexOf(pluralName) !== -1) {
    return true
  } else if (
    (user.isEnterprise || user.isPro) &&
    proCRUDIndexes.indexOf(pluralName) !== -1
  ) {
    return true
  } else if (
    user.isEnterprise &&
    enterpriseNavIndexes.indexOf(pluralName) !== -1
  ) {
    return true
  } else if (user.canUseDomain && pluralName === 'domains') {
    return true
  } else if (
    user.canUpsell &&
    ['marketplace', 'marketplaceItems'].indexOf(pluralName) !== -1
  ) {
    return true
  } else if (user.canTheme && pluralName === 'themes') {
    return true
  } else if (user.canCreateCategory && pluralName === 'categories') {
    return true
  } else if (user.canUseSecurity && pluralName === 'tokens') {
    return true
  }
  return false
}

export const filterPageAccess = (pluralName, user) => {
  // MN - let's show all the indexes to everyone, but display and upgrade dialog for users who
  // do not have access.... this logic essentially being deferred to ItemList/index.js but we'll keep the filter here
  // see below function for filterListPage
  if (hostPageIndexes.indexOf(pluralName) !== -1) {
    return true
  } else if (
    (user.isEnterprise || user.isPro) &&
    proPageIndexes.indexOf(pluralName) !== -1
  ) {
    return true
  } else if (
    user.isEnterprise &&
    enterprisePageIndexes.indexOf(pluralName) !== -1
  ) {
    return true
  } else if (user.canUseDomain && pluralName === 'domains') {
    return true
  } else if (user.canUpsell && pluralName === 'marketplace') {
    return true
  } else if (user.canTheme && pluralName === 'themes') {
    return true
  } else if (user.canCreateCategory && pluralName === 'categories') {
    return true
  } else if (user.canUseSecurity && pluralName === 'tokens') {
    return true
  }
  return false
}

const hostIconTemplates = (
  <FontIcon className="hf-icon-color">developer_board</FontIcon>
)
const hostIconGuidebooks = (
  <FontIcon className="hf-icon-color">import_contacts</FontIcon>
)
const hostIconHostintros = <FontIcon className="hf-icon-color">face</FontIcon>
const hostIconDirections = (
  <FontIcon className="hf-icon-color">directions</FontIcon>
)
const hostIconDomains = <FontIcon className="hf-icon-color">domain</FontIcon>
const hostIconParkings = (
  <FontIcon className="hf-icon-color">local_parking</FontIcon>
)
const hostIconCheckins = <FontIcon className="hf-icon-color">vpn_key</FontIcon>
const hostIconWifis = <FontIcon className="hf-icon-color">wifi</FontIcon>
const hostIconCheckouts = <FontIcon className="hf-icon-color">list</FontIcon>
const hostIconThemes = <FontIcon className="hf-icon-color">settings</FontIcon>
const hostIconInformations = <FontIcon className="hf-icon-color">home</FontIcon>
const hostIconRecommendations = (
  <FontIcon className="hf-icon-color">favorite</FontIcon>
)
const hostIconCategories = (
  <FontIcon className="hf-icon-color">category</FontIcon>
)
const hostIconMarketplace = (
  <FontIcon className="hf-icon-color">shopping_cart</FontIcon>
)
const hostIconSecurity = <FontIcon className="hf-icon-color">security</FontIcon>
const hostIconListings = <FontIcon className="hf-icon-color">location_on</FontIcon>

export const hostNavIcons = {
  guidebooks: hostIconGuidebooks,
  templates: hostIconTemplates,
  hostintros: hostIconHostintros,
  directions: hostIconDirections,
  domains: hostIconDomains,
  parkings: hostIconParkings,
  checkins: hostIconCheckins,
  wifis: hostIconWifis,
  checkouts: hostIconCheckouts,
  themes: hostIconThemes,
  informations: hostIconInformations,
  recommendations: hostIconRecommendations,
  categories: hostIconCategories,
  marketplace: hostIconMarketplace,
  tokens: hostIconSecurity,
  listings: hostIconListings
}

export const hostNavMessages = defineMessages({
  guidebooks: {
    id: 'gb.host.guidebooks',
    defaultMessage: 'Guidebooks'
  },
  templates: {
    id: 'gb.host.templates',
    defaultMessage: 'Templates'
  },
  categories: {
    id: 'gb.categories',
    defaultMessage: 'Categories'
  },
  tokens: {
    id: 'gb.tokens',
    defaultMessage: 'Secure Links'
  },
  marketplace: {
    id: 'gb.marketplace',
    defaultMessage: 'Marketplace'
  },
  hostintros: {
    id: 'gb.host.intros',
    defaultMessage: 'Intros'
  },
  directions: {
    id: 'gb.host.directions',
    defaultMessage: 'Directions'
  },
  domains: {
    id: 'gb.host.domains',
    defaultMessage: 'Domains'
  },
  parkings: {
    id: 'gb.host.parking',
    defaultMessage: 'Parking'
  },
  checkins: {
    id: 'gb.host.checkins',
    defaultMessage: 'Check-ins'
  },
  wifis: {
    id: 'gb.host.wifis',
    defaultMessage: 'Wifis'
  },
  checkouts: {
    id: 'gb.host.checkouts',
    defaultMessage: 'Checkouts'
  },
  themes: {
    id: 'gb.host.themes',
    defaultMessage: 'Themes'
  },
  informations: {
    id: 'gb.host.informations',
    defaultMessage: 'House Manual'
  },
  listings: {
    id: 'gb.host.listings',
    defaultMessage: 'Book Again'
  },
  recommendations: {
    id: 'gb.host.recommendations',
    defaultMessage: 'Recommendations'
  }
})

// These are constants for Admins
export const adminNavIndexes = ['stats', 'exports']
const statsIcon = <FontIcon className="hf-icon-color">timeline</FontIcon>
const exportsIcon = <FontIcon className="hf-icon-color">save_alt</FontIcon>
export const adminNavIcons = {
  stats: statsIcon,
  exports: exportsIcon
}

export const adminNavMessages = defineMessages({
  stats: {
    id: 'gb.stats',
    defaultMessage: 'Stats'
  },
  exports: {
    id: 'gb.exports',
    defaultMessage: 'Exports'
  }
})

export const checkinMessages = defineMessages({
  checkInDate: {
    id: 'gb.checkInDate',
    defaultMessage: 'Check-in date is {dateValue}'
  },
  checkInTime: {
    id: 'gb.checkInTime',
    defaultMessage: 'Check-in time is anytime after {timeValue}'
  },
  checkInRange: {
    id: 'gb.checkInRange',
    defaultMessage: 'Check-in time is between {startTime} and {endTime}'
  },
  earlyCheckinNever: {
    id: 'gb.earlyCheckinNever',
    defaultMessage:
      'Unfortunately we cannot accommodate checking in before this time'
  },
  earlyCheckinSometimes: {
    id: 'gb.earlyCheckinSometimes',
    defaultMessage:
      'Occasionally we accommodate checking in at other times, if you contact us'
  },
  earlyCheckinAlternative: {
    id: 'gb.earlyCheckinAlternative',
    defaultMessage:
      'We are happy to arrange alternative check-in times, if you contact us'
  },
  bagStorageAllowed: {
    id: 'gb.bagStorageAllowed',
    defaultMessage: 'You can store your bags in the listing all day'
  },
  selfAccessLockbox: {
    id: 'gb.selfAccessLockbox',
    defaultMessage: 'There is a lockbox containing your house key'
  },
  selfAccessKeyless: {
    id: 'gb.selfAccessKeyless',
    defaultMessage: 'We have keyless entry'
  },
  selfAccessMeet: {
    id: 'gb.selfAccessMeet',
    defaultMessage: "We'll be there to meet you, if not contact me on arrival."
  },
  selfAccessCodes: {
    id: 'gb.selfAccessCodes',
    defaultMessage: 'We will email you the access codes prior to your arrival'
  },
  selfAccessEmail: {
    id: 'gb.selfAccessEmail',
    defaultMessage: 'Check your email for check-in instructions'
  },
  addtlCheckinInfo: {
    id: 'gb.addtlCheckinInfo',
    defaultMessage: 'Additional check-in information'
  },
  gainingAccess: {
    id: 'bg.gainingAccess',
    defaultMessage: 'Gaining Access'
  },
  bagStorage: {
    id: 'bg.bagStorage',
    defaultMessage: 'Bag Storage'
  },
  Arrival: {
    id: 'gb.Arrival',
    defaultMessage: 'Arrival'
  },
  Checkin: {
    id: 'gb.Checkin',
    defaultMessage: 'Check in'
  },
  enterYourOwn: {
    id: 'gb.EnterYourOwn',
    defaultMessage: 'Enter your own'
  },
  checkinIndicateEarliest: {
    id: 'gb.checkinIndicateEarliest',
    defaultMessage: 'Please indicate the earliest time that guests can check in'
  },
  checkinPolicyDescription: {
    id: 'gb.policydescription',
    defaultMessage:
      'Select the option that best describes your policy regarding check-ins'
  },
  checkinInfo: {
    id: 'gb.checkinInfo',
    defaultMessage: 'Check-in information'
  },
  provideAdditionalcheckin: {
    id: 'gb.provideAdditionalcheckin',
    defaultMessage: 'Provide any additional check-in information'
  },
  howWillGuestsGainAccess: {
    id: 'gb.howWillGuestsGainAccess',
    defaultMessage: 'How will guests gain access to your listing?'
  },
  otherCheckinInformation: {
    id: 'gb.otherCheckinInformation',
    defaultMessage:
      'What other information do your guests need in order to check in?'
  },
  access: {
    id: 'gb.access',
    defaultMessage: 'Access'
  },
  anythingElse: {
    id: 'gb.anythingElse',
    defaultMessage: 'Anything else your guests need to know?'
  },
  otherInformation: {
    id: 'gb.otherInformation',
    defaultMessage:
      'What other information do your guests need in order to check in?'
  }
})

export const securityMessages = defineMessages({
  noAccess: {
    id: 'gb.noAccess',
    defaultMessage: 'You do not have access to this information'
  }
})

export const wifiMessages = defineMessages({
  WiFi: {
    id: 'gb.WiFi',
    defaultMessage: 'Access Wifi'
  },
  dontProvideBut: {
    id: 'gb.dontProvideBut',
    defaultMessage:
      "We don't provide a wifi network but you can attempt to join a nearby network."
  },
  weDontProvide: {
    id: 'gb.WeDontProvide',
    defaultMessage:
      "We don't provide a wifi network and there are no nearby networks you can join as we don't have access here."
  },
  gainAccess: {
    id: 'gb.gainAccess',
    defaultMessage:
      'To attempt to gain access to this free network, select the network on your device with the following name:'
  },
  cantHelp: {
    id: 'gb.cantHelp',
    defaultMessage:
      "Unfortunately we can't help with any issues you may have with this network."
  },
  alsoCafe: {
    id: 'gb.alsoCafe',
    defaultMessage: 'You can also access wifi at: {externalCafeName}'
  },
  canCafe: {
    id: 'gb.canCafe',
    defaultMessage: 'You can access wifi at: {externalCafeName}'
  },
  selectNetwork: {
    id: 'gb.selectNetwork',
    defaultMessage:
      'To attempt to gain access to this free network, select the {networkName} network on your device.'
  },
  cantHelpWithNetwork: {
    id: 'gb.cantHelpWithNetwork',
    defaultMessage:
      "Unfortunately we can't help with any issues you may have with this network."
  },
  passwordCopiedToClipboard: {
    id: 'gb.CopiedToClipboard',
    defaultMessage: 'The Wifi password has been copied to the clipboard.'
  },
  networkDetails: {
    id: 'gb.NetworkDetails',
    defaultMessage: 'Network Name'
  },
  passwordText: {
    id: 'gb.PasswordText',
    defaultMessage: 'Password'
  },
  ifIssuesCallOn: {
    id: 'gb.IssuesCallOn',
    defaultMessage:
      'If you have any problems with the wifi just give me a call on {hostPhone}. '
  },
  ifIssuesContact: {
    id: 'gb.IssuesContact',
    defaultMessage: 'If you have any problems with the wifi please contact us.'
  },
  youCanAccessWifi: {
    id: 'gb.AccessWifiAt',
    defaultMessage: 'You can access wifi at: {cafe_address}'
  },
  howToResolveWifi: {
    id: 'gb.howToResolveWifi',
    defaultMessage: 'How to resolve Wifi issues:'
  },
  otherWifiInfo: {
    id: 'gb.OtherWifiInfo',
    defaultMessage: 'Other information about the Wifi'
  }
})

// icons for possible items in checkout pane
const checkoutLate = <FontIcon>timelapse</FontIcon>
const checkoutStoreBagsAfterCheckout = <FontIcon>work</FontIcon>
const checkoutSignGuestbook = <FontIcon>contact_mail</FontIcon>
const checkoutBeds = <FontIcon>hotel</FontIcon>
const checkoutDishes = <FontIcon>local_cafe</FontIcon>
const checkoutElectrical = <FontIcon>power</FontIcon>
const checkoutFurniture = <FontIcon>event_seat</FontIcon>
const checkoutTrash = <FontIcon>delete</FontIcon>
const checkoutLaundry = <FontIcon>local_laundry_service</FontIcon>
const checkoutDoorUnlocked = <FontIcon>lock_open</FontIcon>
const checkoutLockDoors = <FontIcon>lock_outline</FontIcon>
const checkoutKey = <FontIcon>vpn_key</FontIcon>
const checkoutContent = <FontIcon>list</FontIcon>

export const checkoutIcons = {
  place: placeIcon,
  time: timeIcon,
  content: checkoutContent,
  late: checkoutLate,
  store_bags_after_checkout: checkoutStoreBagsAfterCheckout,
  sign_guestbook: checkoutSignGuestbook,
  beds_unmade: checkoutBeds,
  clean_dishes: checkoutDishes,
  dishes_in_washer: checkoutDishes,
  electrical_off: checkoutElectrical,
  rearrange_furniture: checkoutFurniture,
  take_out_trash: checkoutTrash,
  towels_in_tub: checkoutLaundry,
  towels_on_floor: checkoutLaundry,
  door_unlocked: checkoutDoorUnlocked,
  lock_doors: checkoutLockDoors,
  key_inside: checkoutKey,
  key_lockbox: checkoutKey
}

export const checkoutItemHeadings = {
  store_bags_after_checkout: 'store_luggage',
  sign_guestbook: 'guestbook',
  beds_unmade: 'beds',
  clean_dishes: 'dishes',
  dishes_in_washer: 'dishes',
  electrical_off: 'power',
  rearrange_furniture: 'furniture',
  take_out_trash: 'trash',
  towels_in_tub: 'towels',
  towels_on_floor: 'towels',
  door_unlocked: 'doors',
  lock_doors: 'doors',
  key_inside: 'keys',
  key_lockbox: 'keys'
}

export const checkoutItems = {
  checkout: ['store_bags_after_checkout', 'sign_guestbook'],
  cleanup: [
    'beds_unmade',
    'clean_dishes',
    'dishes_in_washer',
    'electrical_off',
    'rearrange_furniture',
    'take_out_trash',
    'towels_in_tub',
    'towels_on_floor',
    'door_unlocked',
    'lock_doors',
    'key_inside',
    'key_lockbox'
  ]
}

export const checkoutMessages = defineMessages({
  checkout: {
    id: 'gb.checkout',
    defaultMessage: 'Checkout'
  },
  departure: {
    id: 'gb.departure',
    defaultMessage: 'Departure'
  },
  beforeLeaving: {
    id: 'gb.beforeLeaving',
    defaultMessage: 'Before leaving:'
  },
  checkoutPrimary: {
    id: 'gb.checkoutPrimary',

    defaultMessage: 'Checkout time'
  },
  checkOutDate: {
    id: 'gb.checkOutDate',
    defaultMessage: 'Checkout date is {dateValue}'
  },
  checkoutTime: {
    id: 'gb.checkoutTime',
    defaultMessage: 'Checkout time is anytime before {timeValue}'
  },
  unknownCheckoutTime: {
    id: 'gb.unknownCheckoutTime',
    defaultMessage: 'Contact host for checkout times'
  },
  lateCheckout: {
    id: 'gb.lateCheckout',

    defaultMessage: 'Late checkout'
  },
  lateCheckoutContact: {
    id: 'gb.lateCheckoutContact',
    defaultMessage: "Contact us if you'd like to arrange a later checkout"
  },
  lateCheckoutSometimes: {
    id: 'gb.lateCheckoutSometimes',
    defaultMessage:
      'Sometimes we can accommodate late checkouts if you contact us'
  },
  lateCheckoutNo: {
    id: 'gb.lateCheckoutNo',
    defaultMessage: "Unfortunately we can't accommodate late checkouts"
  },
  showLateCheckout: {
    id: 'gb.showLateCheckout',
    defaultMessage:
      "Show late checkout option (if unchecked we won't mention it at all)"
  },
  store_luggage: {
    id: 'gb.storeLuggage',

    defaultMessage: 'Luggage storage'
  },
  store_bags_after_checkout: {
    id: 'gb.storeBagsAfterCheckout',
    defaultMessage: 'You can store your bags at the listing after checkout time'
  },
  guestbook: {
    id: 'gb.guestBook',

    defaultMessage: 'Guestbook'
  },
  sign_guestbook: {
    id: 'gb.signGuestBook',
    defaultMessage: 'Please sign our guestbook before you leave'
  },
  beds: {
    id: 'gb.beds',

    defaultMessage: 'Bedding'
  },
  beds_unmade: {
    id: 'gb.bedsUnmade',
    defaultMessage: 'Leave used beds unmade'
  },
  dishes: {
    id: 'gb.dishes',

    defaultMessage: 'Dirty Dishes'
  },
  clean_dishes: {
    id: 'gb.cleanDishes',
    defaultMessage: 'Please clean up any dirty dishes and put them away'
  },
  dishes_in_washer: {
    id: 'gb.dishesInWasher',
    defaultMessage: 'Put any last minute dishes in the dishwasher'
  },
  power: {
    id: 'gb.power',
    defaultMessage: 'Power off'
  },
  electrical_off: {
    id: 'gb.electricalOff',
    defaultMessage: 'Make sure you turn off the stove, heater and lights'
  },
  furniture: {
    id: 'gb.furniture',
    defaultMessage: 'Furniture'
  },
  rearrange_furniture: {
    id: 'gb.rearrangeFurniature',
    defaultMessage: 'Replace any furniture that was rearranged'
  },
  trash: {
    id: 'gb.trash',
    defaultMessage: 'Trash'
  },
  take_out_trash: {
    id: 'gb.takeOutTrash',
    defaultMessage: 'Take out the trash'
  },
  towels: {
    id: 'gb.towels',
    defaultMessage: 'Towels'
  },
  towels_in_tub: {
    id: 'gb.towelsInTub',
    defaultMessage: 'Leave used towels in the tub'
  },
  towels_on_floor: {
    id: 'gb.towelsOnFloor',
    defaultMessage: 'Leave used towels on the floor'
  },
  doors: {
    id: 'gb.doors',
    defaultMessage: 'Doors'
  },
  door_unlocked: {
    id: 'gb.doorUnlocked',
    defaultMessage: 'Leave the door unlocked'
  },
  lock_doors: {
    id: 'gb.LockDoors',
    defaultMessage: 'Make sure you lock the doors'
  },
  keys: {
    id: 'gb.keys',
    defaultMessage: 'Keys'
  },
  key_inside: {
    id: 'gb.keyInside',
    defaultMessage: 'Leave a key inside'
  },
  key_lockbox: {
    id: 'gb.keyLockbox',
    defaultMessage: 'Leave the key(s) in the lockbox'
  },
  addtlCheckoutInfo: {
    id: 'gb.addtlCheckoutInfo',
    defaultMessage: 'Additional checkout information'
  },
  customTextPrompt: {
    id: 'gb.customTextPrompt',
    defaultMessage: 'Add any other checkout instructions here'
  },
  customTextSubPrompt: {
    id: 'gb.customTextSubPrompt',
    defaultMessage:
      'What other information do you want guests to know for checkout?'
  }
})

const directionsParking = <FontIcon>local_parking</FontIcon>
const directionsTaxi = <FontIcon>local_taxi</FontIcon>
const directionsTrain = <FontIcon>train</FontIcon>
const directionsCar = <FontIcon>directions_car</FontIcon>
const directionsBoat = <FontIcon>directions_boat</FontIcon>
const directionsAirport = <FontIcon>local_airport</FontIcon>
const directionsBus = <FontIcon>directions_bus</FontIcon>
const directionsOverview = <FontIcon>directions</FontIcon>

export const directionsIcons = {
  place: placeIcon,
  map: mapIcon,
  parking: directionsParking,
  taxi: directionsTaxi,
  uber: directionsTaxi,
  lyft: directionsTaxi,
  train: directionsTrain,
  ferry: directionsBoat,
  driving: directionsCar,
  pickup: directionsCar,
  airport: directionsAirport,
  bus: directionsBus,
  overview: directionsOverview
}

export const directionsMessages = defineMessages({
  gettingHere: {
    id: 'gb.gettingHere',
    defaultMessage: 'Getting here'
  },
  arrival: {
    id: 'gb.arrival',
    defaultMessage: 'Arrival'
  },
  recApproaches: {
    id: 'gb.recommendedApproaches',
    defaultMessage: 'Recommended approaches'
  },
  directionsPrompt: {
    id: 'gb.directionsPrompt',
    defaultMessage:
      'Select the methods you would like to include specific directions for'
  },
  directionsSubPrompt: {
    id: 'gb.directionsSubPrompt',
    defaultMessage: 'Only select the options you recommend guests use'
  },
  parking: {
    id: 'gb.parking',
    defaultMessage: 'Parking'
  },
  selectAll: {
    id: 'gb.selectAll',
    defaultMessage: 'Select all'
  },
  airport: {
    id: 'gb.toFromAirport',
    defaultMessage: 'To/from airport'
  },
  airportHost: {
    id: 'gb.hostToFromAirport',
    defaultMessage: 'travelling to or from the airport'
  },
  taxi: {
    id: 'gb.taxi',
    defaultMessage: 'Taxi'
  },
  uber: {
    id: 'gb.uber',
    defaultMessage: 'Uber'
  },
  uberReferralAlso: {
    id: 'gb.uberReferralAlso',
    defaultMessage:
      'Also, if you are a new user and sign up using my invite link below, Uber will discount your first ride, so you can have a very cheap ride from the airport if you use '
  },
  uberReferral: {
    id: 'gb.uberReferral',
    defaultMessage:
      'If you are a new user and sign up using my invite link below, Uber will discount your first ride, so you can have a very cheap ride from the airport if you use '
  },
  uberReferralPrompt: {
    id: 'gb.uberReferralPrompt',
    defaultMessage: 'Uber referral code'
  },
  uberReferralDesc: {
    id: 'gb.uberReferralDesc',
    defaultMessage:
      'Do you have an Uber promo/referral code? Enter your code or invite link here to earn credit with Uber and give your guests a savings off their first ride'
  },
  uberReferralDesc2: {
    id: 'gb.uberReferralDesc2',
    defaultMessage:
      'You can find your referral code at https://riders.uber.com/invite'
  },
  lyft: {
    id: 'gb.lyft',
    defaultMessage: 'Lyft'
  },
  lyftReferralAlso: {
    id: 'gb.lyftReferralAlso',
    defaultMessage:
      'Also, if you are a new user and sign up using my invite link below, Lyft will discount your first ride, so you can have a very cheap ride from the airport if you use '
  },
  lyftReferral: {
    id: 'gb.lyftReferral',
    defaultMessage:
      'If you are a new user and sign up using my invite link below, Lyft will discount your first ride, so you can have a very cheap ride from the airport if you use '
  },
  lyftReferralPrompt: {
    id: 'gb.lyftReferralPrompt',
    defaultMessage: 'Lyft referral code'
  },
  lyftReferralDesc: {
    id: 'gb.lyftReferralDesc',
    defaultMessage:
      'Do you have a Lyft referral code? Enter your code or invite URL here to earn credit with Lyft and give your guests a savings off their first ride'
  },
  lyftReferralDesc2: {
    id: 'gb.lyftReferralDesc2',
    defaultMessage:
      'You can find your referral code or make a new one at https://www.lyft.com/passenger/referrals'
  },
  bus: {
    id: 'gb.bus',
    defaultMessage: 'Bus'
  },
  ferry: {
    id: 'gb.ferry',
    defaultMessage: 'Ferry-boat'
  },
  train: {
    id: 'gb.train',
    defaultMessage: 'Train'
  },
  pickup: {
    id: 'gb.pickup',
    defaultMessage: 'Pickup'
  },
  pickupHost: {
    id: 'gb.hostPickup',
    defaultMessage: 'who are getting picked up'
  },
  driving: {
    id: 'gb.driving',
    defaultMessage: 'Driving'
  },
  drivingHost: {
    id: 'gb.hostDriving',
    defaultMessage: 'who are driving themselves'
  },

  on_street_available: {
    id: 'gb.parkingOnStreetAvailable',
    defaultMessage: 'On-street parking is readily available.'
  },
  driveway_available: {
    id: 'gb.parkingDrivewayAvailable',
    defaultMessage: 'You can park in the driveway.'
  },
  economical_available: {
    id: 'gb.parkingEconomicalAvailable',
    defaultMessage: 'Economical parking stations are available nearby.'
  },
  expensive_nearby: {
    id: 'gb.parkingExpensiveNearby',
    defaultMessage: 'Parking is expensive at nearby facilities.'
  },
  address: {
    id: 'gb.address',
    defaultMessage: 'Address'
  },
  additional_directions: {
    id: 'gb.additionalDirections',
    defaultMessage: 'Additional directions'
  }
})

export const hostUIMessages = defineMessages({
  nameHeading: {
    id: 'hu.nameHeading',
    defaultMessage: 'Name this card so you can find it later'
  },
  languageHeading: {
    id: 'hu.langHeading',
    defaultMessage:
      'To create this card in different languages, choose them here'
  },
  languageSubHeading: {
    id: 'hu.langSubHeading',
    defaultMessage:
      'This will activate tabs for each language in the text fields on this form'
  }
})

export const safelyMessages = defineMessages({
  header: {
    id: 'sfly.header',
    defaultMessage: 'Protect yourself from paying for the unexpected'
  },
  subheader: {
    id: 'sfly.subheader',
    defaultMessage:
      'You have the opportunity to protect your trip by purchasing travel insurance.'
  },
  details: {
    id: 'sfly.details',
    defaultMessage:
      'Protect up to 100% of covered reasons: illness (including contracting COVID), injury or death in the family or to someone in the traveling party, natural disasters, travel delays, and much more.'
  },
  providedBy: {
    id: 'sfly.providedBy',
    defaultMessage: 'Travel insurance is powered by:'
  },
  tellMeMore: {
    id: 'sfly.tellMeMore',
    defaultMessage: 'Tell me more'
  },
  viewGuidebook: {
    id: 'sfly.viewGuidebook',
    defaultMessage: 'No thanks! View the guidebook'
  }
})

export const splashMessages = defineMessages({
  listingAlt: {
    id: 'spl.listingAlt',
    defaultMessage: 'Featured view of the listing'
  },
  supplyDetails: {
    id: 'spl.supplyDetails',
    defaultMessage:
      'Please supply your details to acknowledge that you accept the terms.'
  },
  email: {
    id: 'spl.email',
    defaultMessage: 'Email'
  },
  emailError: {
    id: 'spl.emailError',
    defaultMessage: 'Please enter a valid email address'
  },
  firstName: {
    id: 'spl.firstName',
    defaultMessage: 'First Name'
  },
  fieldRequired: {
    id: 'spl.fieldRequired',
    defaultMessage: 'This field is required'
  },
  lastName: {
    id: 'spl.lastName',
    defaultMessage: 'Last Name'
  },
  getAccess: {
    id: 'spl.getAccess',
    defaultMessage: 'Get Access'
  }
})

export const tokenFields = [
  { key: '', label: 'Select a Field' },
  { key: 'access_code', label: 'Access Code' },
  { key: 'guest_first', label: 'Guest First Name' },
  { key: 'guest_last', label: 'Guest Last Name' },
  { key: 'guest_email', label: 'Guest Email' },
  { key: 'checkin_date', label: 'Check-in Date' },
  { key: 'checkout_date', label: 'Checkout Date' },
  { key: 'status', label: 'Reservation Status' },
  { key: 'unit_type_name', label: 'Unit Type' },
  { key: 'unit_name', label: 'Unit' },
  { key: 'unit_type_uid',  label: 'Unit type uid'},
  { key: 'unit_uid',  label: 'Unit uid'}
]

export const prefixFields = (fields, prefix) => {
  return fields.map((item) => {
    const key = item.key === '' ? item.key : `${prefix}.${item.key}`
    const label = item.label
    return { key: key, label: label }
  })
}

export const getLabelForField = (key) => {
  if (!key) return null
  for (let i = 0, len = tokenFields.length; i < len; i++) {
    if (tokenFields[i].key === key) return tokenFields[i].label
  }
  return null
}

export const wizardSteps = [
  {
    stepName: 'choice',
    loadPrereqs: false,
    model: null,
    pluralName: null,
    labelField: null,
    title: 'Guidebook Quick Start'
  },
  {
    stepName: 'location',
    loadPrereqs: false,
    model: 'guidebook',
    pluralName: 'guidebooks',
    labelField: 'name',
    title: 'Location'
  },
  {
    stepName: 'recsstart',
    loadPrereqs: false,
    model: null,
    pluralName: null,
    labelField: null,
    title: 'Local Recommendations'
  },
  {
    stepName: 'checkin',
    loadPrereqs: true,
    model: 'checkin',
    pluralName: 'checkins',
    labelField: 'label',
    title: 'Check-in'
  },
  {
    stepName: 'checkout',
    loadPrereqs: true,
    model: 'checkout',
    pluralName: 'checkouts',
    labelField: 'label',
    title: 'Checkout'
  },
  {
    stepName: 'wifi',
    loadPrereqs: true,
    model: 'wifi',
    pluralName: 'wifis',
    labelField: 'label',
    title: 'Wifi'
  },
  {
    stepName: 'intro',
    loadPrereqs: true,
    model: 'hostintro',
    pluralName: 'hostintros',
    labelField: 'label',
    title: 'Introductions'
  },
  // {stepName: 'directions', loadPrereqs: true, model: 'directions', pluralName: 'directions', labelField: 'label', title: 'Directions'},
  {
    stepName: 'parking',
    loadPrereqs: true,
    model: 'parking',
    pluralName: 'parkings',
    labelField: 'label',
    title: 'Parking'
  },
  {
    stepName: 'rules',
    loadPrereqs: true,
    model: 'information',
    pluralName: 'informations',
    labelField: 'name',
    title: 'House Rules'
  },
  // {stepName: 'laundry', loadPrereqs: true, model: 'information', pluralName: 'informations', labelField: 'name', title: 'Laundry / Cleaning'},
  // {stepName: 'emergency', loadPrereqs: true, model: 'information', pluralName: 'informations', labelField: 'name', title: 'Emergency Contact Numbers'},
  {
    stepName: 'congrats',
    loadPrereqs: false,
    model: null,
    pluralName: null,
    labelField: null,
    title: 'Congratulations!'
  }
]
